.card-list-header {
  display: grid;
  grid-template: "heading more" auto
                 "intro intro" auto
                / 1fr max-content;
  align-items: start;
}

.card-list-header__heading {
  grid-area: heading;
}

.theme-lex .card-list-header__heading {
  font: var(--front-subheading);
}

.theme-lille .card-list-header__heading {
  font: var(--article-subheading);
}

.card-list-header__link {
  grid-area: more;
}

.card-list-header__link:not(:hover) {
  border-bottom-color: transparent;
}

.card-list-header__link-text {
  font: var(--tagline);
  text-transform: uppercase;
}

.theme-lex .card-list-header__link-text {
  font: var(--meta);
  text-transform: none;
}

.card-list-header__icon {
  vertical-align: middle;
}

.card-list-header__intro-wrapper {
  grid-area: intro;
}

.card-list-header__intro {
  font: var(--small-body);
  margin: 0.25rem 0 0;
}

.theme-lex .card-list-header__intro {
  font: var(--front-preamble);
}

.theme-lille .card-list-header__intro {
  font: var(--body);
}

@media (--small) {
  .card-list-header__link-text {
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
  }
}

@media (--medium-up) {
  .card-list-header__intro-wrapper {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: var(--gutter);
  }

  .card-list-header__intro {
    grid-column: 1 / span 4;
  }
}

@media (--medium-plus-up) {
  .card-list-header__intro-wrapper {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .card-list-header__intro {
    grid-column: 1 / span 6;
  }
}

@media (--large-up) {
  .card-list-header__intro-wrapper {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .card-list-header__intro {
    grid-column: 1 / span 8;
  }
}
