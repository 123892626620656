.l-front__title {
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
}

.l-front__most-read-large {
  border-top: 1px solid;
  padding: var(--spacing3) 0;
}

.l-front__more-tagsonomies {
  padding-bottom: var(--spacing3);
}

.l-front__more-tagsonomies:before {
  content: "";
  display: block;
  border-top: 1px solid;
  width: 100%;
}

.l-front__years {
  margin: var(--spacing3) 0;
}

.l-front__about-encyclopedia {
  padding-top: var(--spacing3);
  border-top: 1px solid;
  padding-bottom: var(--spacing1);
}

.l-front__main {
  margin-top: var(--spacing2);
}

.l-front__category-list {
  margin-bottom: var(--spacing3);
}

:not(.l-front__category-list) + .l-front__category-list {
  padding-top: var(--spacing3);
  border-top: 1px solid;
}

@media (--small) {
  .l-front__snl-lists {
    display: flex;
    flex-direction: column;
  }

  .l-front__read-stats {
    margin-bottom: var(--spacing3);
  }

  .l-front__read-stats-item {
    border-top: 1px solid;
    padding-top: var(--spacing1);
  }

  .l-front__read-stats-item:nth-child(n+2) {
    margin-top: var(--spacing1);
  }

  .l-front__snl-tagsonomy {
    margin-bottom: var(--spacing1);
  }

  .l-front__snl-category {
    order: 1;
    border-top: 1px solid;
    padding-top: var(--spacing1);
    margin-bottom: var(--spacing1);
  }
}

@media (--medium-up) {
  .l-front__snl-lists {
    display: flex;
    flex-wrap: wrap;
  }

  .l-front__snl-lists:before {
    content: "";
    display: block;
    flex: none;
    width: calc(100% - var(--gutter));
    border-top: 1px solid;
    margin: 0 var(--half-gutter) var(--spacing2);
  }

  .l-front__read-stats {
    width: 100%;
    display: flex;
  }

  .l-front__read-stats-item {
    flex: none;
    width: 33.33%;
    padding: 0 var(--half-gutter);
    margin-bottom: var(--spacing2);
  }

  .l-front__snl-tagsonomy {
    flex: none;
    width: 50%;
    padding: 0 var(--half-gutter);
    margin-bottom: var(--spacing2);
  }

  .l-front__snl-category {
    order: 1;
    flex: none;
    width: 33.33%;
    padding: 0 var(--half-gutter);
    margin-bottom: var(--spacing2);
  }

  .l-front__encyclopedias {
    flex: none;
    width: 50%;
    padding: 0 calc(3 * var(--half-gutter));
  }

  .l-front__encyclopedias:before,
  .l-front__encyclopedias:after {
    content: "";
    display: block;
    margin: 0 calc(-1 * var(--gutter));
    border-top: 1px solid;
  }

  .l-front__encyclopedias:before {
    margin-bottom: var(--lineheight-body);
  }

  .l-front__category-list {
    margin: 0 var(--half-gutter) var(--spacing3);
  }

  .l-front__most-read-large {
    margin: 0 var(--half-gutter);
  }

  .l-front__more-tagsonomies:before {
    margin: 0 var(--half-gutter);
  }

  .l-front__years,
  .l-front__tagsonomy-teaser {
    padding: 0 var(--half-gutter);
  }

  .l-front__about-encyclopedia {
    margin: 0 var(--half-gutter);
  }
}

@media (--medium-plus-up) {
  .l-front__snl-lists:after {
    content: "";
    display: block;
    flex: none;
    width: calc(100% - var(--gutter));
    border-top: 1px solid;
    margin: 0 var(--half-gutter) var(--spacing2);
  }

  .l-front__read-stats {
    width: calc(6 / 10 * 100%);
  }

  .l-front__snl-tagsonomy {
    width: calc(4 / 10 * 100%);
  }

  .l-front__snl-category {
    width: calc(2 / 10 * 100%);
  }

  .l-front__encyclopedias {
    order: 2;
    width: calc(4 / 10 * 100%);
    position: relative;
    padding-top: var(--lineheight-body);
    padding-right: calc(1.5 / 10 * 100%);
  }

  .l-front__encyclopedias:before {
    margin: 0;
    border-top: none;
    position: absolute;
    top: 0;
    bottom: var(--spacing2);
    left: var(--half-gutter);
    border-left: 1px solid;
  }

  .l-front__encyclopedias:after {
    display: none;
  }

  .l-front__category-list {
    padding-right: calc(1 / 10 * 100%);
  }

  :not(.l-front__category-list) + .l-front__category-list {
    padding-top: var(--spacing3);
    border-top: 1px solid;
  }

  .l-front__more-tagsonomies {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 0;
  }

  .l-front__years {
    width: calc(6 / 10 * 100%);
  }

  .l-front__tagsonomy-teaser {
    width: calc(4 / 10 * 100%);
  }
}

@media (--large-up) {
  .l-front__read-stats {
    width: calc(8 / 12 * 100%);
    padding: 0 calc(1 / 12 * 100%);
  }

  .l-front__snl-tagsonomy {
    width: calc(4 / 12 * 100%);
    padding: 0;
    margin-left: calc(-1 * var(--half-gutter));
  }

  .l-front__snl-category {
    width: calc(2 / 12 * 100%);
    margin-left: calc(1 / 12 * 100%);
  }

  .l-front__snl-category + .l-front__snl-category {
    margin-left: 0;
  }

  .l-front__encyclopedias {
    width: calc(4 / 12 * 100%);
    padding-right: calc(1.5 / 12 * 100%);
    padding-left: var(--half-gutter);
    margin-left: calc(1 / 12 * 100%);
  }

  .l-front__encyclopedias:before {
    left: calc(-1 * var(--half-gutter));
  }

  .l-front__category-list {
    padding-left: calc(1 / 12 * 100%);
    padding-right: calc(2 / 12 * 100%);
  }

  .l-front__most-read-large {
    padding: var(--spacing3) calc(1 / 12 * 100%);
  }

  .l-front__years {
    margin-left: calc(1 / 12 * 100%);
    width: calc(6 / 12 * 100%);
    margin-right: calc(1 / 12 * 100%);
  }

  .l-front__tagsonomy-teaser {
    width: calc(4 / 12 * 100%);
  }

  .l-front__about-encyclopedia {
    padding-left: calc(1 / 12 * 100%);
  }

  .l-front__about-encyclopedia--small {
    padding-right: calc(1 / 12 * 100%);
  }
}
