.form-control {
  font: inherit;
  padding: 2px 0.25em;
  border: 1px solid;
}

.form-control--textarea {
  width: 100%;
  /* For alignment in form-field. Would really like to use 'baseline', but
   * Chromium doesn't handle it, so have to settle for 'top'.
   */
  vertical-align: top;
}

.form-control:invalid,
.form-control--error {
  border-color: var(--color-guardsman-red);
}

@media (--medium-plus-up) {
  .form-control {
    width: 100%;
  }
}
