.login-button {
  color: black;
  font: var(--meta);
  font-weight: 500;
  border: 2px solid;
  padding: 8px 48px 8px 14px;
  background: url(../images/user_dark.svg) center right 14px no-repeat;
  display: inline-flex;
  height: 48px;
  align-items: center;
}

.login-button--white {
  color: white;
  background-image: url(../images/user_white.svg);
}

.login-button:hover,
.login-button:focus {
  border-bottom-color: currentColor;
}
