.highlighted-authors {
  display: none;
}

.highlighted-authors--single {
  position: relative;
  padding-top: calc(var(--profile-image-size) + var(--lineheight-tagline));
}

.highlighted-authors__heading,
.highlighted-authors__author-academic-title {
  font: var(--tagline);
  text-transform: uppercase;
}

.highlighted-authors--multiple .highlighted-authors__heading {
  margin-bottom: var(--lineheight-tagline);
}

.highlighted-authors__list {
  padding: 0;
  margin: 0;
}

.highlighted-authors__item {
  list-style: none;
  margin-bottom: var(--lineheight-tagline);
}

.highlighted-authors__author-link {
  display: inline-block; /* Makes for nicer outline on keyboard focus */
  width: 100%;
  border-bottom-style: none;
}

.highlighted-authors--single .highlighted-authors__image {
  position: absolute;
  top: 0;
  left: 0;
}

.highlighted-authors__author-name {
  font: var(--subtitle);
  font-variant-numeric: var(--numeric);
}

.highlighted-authors__author-link:link .highlighted-authors__author-name {
  border-bottom: 1px solid var(--blue-dianne-25);
}

.highlighted-authors__author-link:link:hover .highlighted-authors__author-name,
.highlighted-authors__author-link:link:focus .highlighted-authors__author-name {
  border-bottom-color: var(--blue-dianne-75);
}

.highlighted-authors__author-link:visited .highlighted-authors__author-name,
.highlighted-authors__author-link:visited .highlighted-authors__author-name {
  border-bottom: 1px solid var(--victoria-25);
}

.highlighted-authors__author-link:visited:hover .highlighted-authors__author-name,
.highlighted-authors__author-link:visited:focus .highlighted-authors__author-name {
  border-bottom-color: var(--victoria-75);
}

.highlighted-authors__institution {
  font: var(--tagline);
  text-transform: uppercase;
}

@media (--large-up) {
  .highlighted-authors {
    display: block;
    overflow: auto; /* In case of long words; SNL don't want to hyphenate/wrap */
  }
}
