.read-stats {
  position: relative;
}

.read-stats__heading {
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
}

.read-stats__subheading {
  font: var(--tagline);
  text-transform: uppercase;
}

.read-stats__count {
  font: var(--stats-font);
}
