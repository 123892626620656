.l-header--hero {
  position: relative;
}

.theme-lille .l-header {
  background: var(--color-silver-tree);
  padding-block: 1px; /* contain margins */
  --visited-color: var(--link-color);
  --visited-underline: var(--link-underline);
  --visited-underline-focus: var(--link-underline-focus);
  --alternate-link-color: var(--link-color);
  --alternate-link-underline: var(--link-underline);
}

.theme-lex .l-header {
  background: var(--color-nobel);
  padding-block: 1px; /* contain margins */
}

.theme-lex .l-header--main-encyclopedia {
  background: var(--color-blue-ribbon);
}

.l-header__content {
  padding: 0 var(--half-gutter);
  margin: var(--spacing1) 0;
}

.l-header--hero .l-header__content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.theme-lex .l-header--hero .l-header__content {
  position: static;
}

.theme-lille .l-header__content {
  margin-block: var(--half-gutter);
}

.l-header__nav {
  display: flex;
  justify-content: center;
  margin: var(--spacing2) var(--gutter) 0;
}

@media (--medium-up) {
  .l-header__content {
    padding: 0 var(--gutter);
    width: var(--grid-width);
    margin: var(--spacing2) auto;
  }
}

@media (--medium-plus-up) {
  .l-header__content {
    margin-bottom: var(--spacing4);
  }
}

@media (--large-up) {
  .l-header__content {
    padding: 0;
    margin-top: var(--spacing1);
    margin-bottom: var(--spacing1);
  }
}
