*, *:before, *:after {
  box-sizing: border-box;
}

*:focus {
  outline: 1px solid var(--outline-color, currentColor);
  outline-offset: 1px;
}

html {
  font: var(--body);
  --body-foreground: var(--blue-dianne);
  --body-background: var(--black-haze);
  --link-color: var(--blue-dianne);
  --link-underline: var(--blue-dianne-25);
  --link-underline-focus: var(--blue-dianne-75);
  --alternate-link-color: var(--allports);
  --alternate-link-underline: var(--allports-75);
  --visited-color: var(--victoria);
  --visited-underline: var(--victoria-25);
  --visited-underline-focus: var(--victoria-75);
}

.theme-lille {
  --body-foreground: var(--color-plantation);
  --body-background: var(--color-gray-nurse);
  --link-color: var(--color-plantation);
  --link-underline: var(--color-plantation-50);
  --link-underline-focus: var(--color-plantation);
  --alternate-link-color: var(--color-sea-green);
  --alternate-link-underline: var(--color-sea-green-75);
}

.theme-lex {
  --body-foreground: black;
  --link-color: black;
}

body {
  margin: 0;
  color: var(--body-foreground);
  background: var(--body-background);
}

h1 {
  margin: 0;
  font: var(--title);
  font-variant-numeric: var(--numeric);
}

h2 {
  margin: 0;
  font: var(--subtitle);
  font-variant-numeric: var(--numeric);
}

h3 {
  margin: 0;
  font: var(--prioritized);
}

h4 {
  margin: 0;
  font: var(--tagline);
  text-transform: uppercase;
}

p, ol, ul, dl, table {
  margin: 0 0 var(--lineheight-body);
}

:link {
  color: var(--link-color);
  text-decoration: none;
  border-bottom: 1px solid var(--link-underline);
}

:link:hover,
:link:focus {
  border-bottom-color: var(--link-underline-focus);
}

:visited {
  color: var(--visited-color);
  text-decoration: none;
  border-bottom: 1px solid var(--visited-underline);
}

:visited:hover,
:visited:focus {
  border-bottom-color: var(--visited-underline-focus);
}

sub, sup {
  line-height: 1;
}

mark {
  background-color: var(--pale-canary);
}

textarea {
  font: var(--meta);
}

button {
  color: inherit;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  text-align: left;
  font: inherit;
  border-bottom: 1px solid var(--silver);
}

* + th,
* + td {
  padding-left: var(--half-gutter);
}
