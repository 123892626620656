.media-preview {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--half-gutter);
  border: 1px solid grey;
}

.media-preview--draggable {
  cursor: move; /* "cursor: grab" doesn't work in Chrome on Ubuntu */
}

.media-preview__image {
  object-fit: cover;
  flex: none;
  background: var(--blue-dianne);
}

.media-preview--draggable .media-preview__image {
  pointer-events: none;
}

.media-preview__extra-elements {
  padding: 0 var(--half-gutter);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
