.article-info {
  font: var(--meta);
}

.article-info__heading {
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
}

.article-info__items {
  margin: 0;
}

.article-info__item-name {
  font: var(--tagline);
  text-transform: uppercase;
  display: inline;
}

.article-info__item-name:before {
  content: "";
  display: block;
}

.article-info__item-value {
  display: inline;
  margin: 0;
}

.article-info__license-type {
  font: var(--tagline);
  text-transform: uppercase;
}

.article-info__license-image {
  vertical-align: middle;
}

.article-info__cite {
  font-style: normal;
  display: block;
  background: white;
  padding: var(--half-gutter);
}

.theme-lille .article-info__cite {
  background: var(--black-haze);
}
