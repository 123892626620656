.image-submit {
  padding: var(--half-gutter);
}

.image-submit__image {
  object-fit: contain;
  flex: none;
}

.image-submit__button {
  cursor: pointer;
}

.image-submit__title {
  display: block;
  padding: var(--half-gutter);
}