.partner-logos__intro {
  font: var(--small-body);
}

.partner-logos__list {
  padding: 0;
  text-align: justify;
}

.partner-logos__list:after {
  content: "";
  display: inline-block;
  width: 100%;
}

.partner-logos__item {
  list-style: none;
}

.partner-logos__link {
  border-bottom: none;
}

.partner-logos__image {
  width: auto;
  height: 40px;
}

.partner-logos__image--large {
  height: 60px;
}

.partner-logos__image--single {
  height: 80px;
  width: 100%;
}

@media (--medium-up) {
  .partner-logos--snl .partner-logos__intro,
  .partner-logos--centered .partner-logos__intro {
    text-align: center;
  }

  .partner-logos__item {
    display: inline; /* inline-block gives too small width in IE11 */
  }
}

@media (--medium) {
  .partner-logos--snl .partner-logos__item:nth-child(4):after,
  .partner-logos--snl .partner-logos__item:nth-child(8):after,
  .partner-logos--snl .partner-logos__item:nth-child(12):after,
  .partner-logos--snl .partner-logos__item:nth-child(16):after {
    content: "";
    display: inline-block;
    width: 100%;
  }
}

@media (--medium-plus) {
  .partner-logos--snl .partner-logos__item:nth-child(5):after,
  .partner-logos--snl .partner-logos__item:nth-child(10):after,
  .partner-logos--snl .partner-logos__item:nth-child(15):after {
    content: "";
    display: inline-block;
    width: 100%;
  }
}

@media (--large-up) {
  .partner-logos--snl .partner-logos__item:nth-child(7):after,
  .partner-logos--snl .partner-logos__item:nth-child(14):after,
  .partner-logos--snl .partner-logos__item:nth-child(21):after {
    content: "";
    display: inline-block;
    width: 100%;
  }
}

@media (--xlarge-up) {
  .partner-logos__image {
    height: 45px;
  }

  .partner-logos__image--large {
    height: 60px;
  }

  .partner-logos__image--single {
    height: 90px;
  }
}

@media (--xlarge-plus-up) {
  .partner-logos__image {
    height: 50px;
  }

  .partner-logos__image--large {
    height: 60px;
  }

  .partner-logos__image--single {
    height: 100px;
  }
}
