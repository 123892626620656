.quality-assessment {
  overflow: auto; /* In case of long words; SNL don't want to hyphenate/wrap */
  font: var(--meta);
}

.quality-assessment:after {
  content: "";
  display: block;
  clear: left;
}

.quality-assessment__heading {
  margin: 0;
  font: var(--tagline);
  text-transform: uppercase;
}

.quality-assessment__existing {
  margin: var(--half-gutter) 0;
}

.quality-assessment__existing-heading {
  font: var(--meta-bold);
}

.quality-assessment__score {
  display: inline-block;
  margin-right: 0.25em;
  margin-bottom: 0.25em;
  padding: 0.1em 0.6em;
  text-align: center;
}

.quality-assessment__score--1 {
  color: var(--color-score-1-fg);
  background: var(--color-score-1-bg);
}

.quality-assessment__score--2 {
  color: var(--color-score-2-fg);
  background: var(--color-score-2-bg);
}

.quality-assessment__score--3 {
  color: var(--color-score-3-fg);
  background: var(--color-score-3-bg);
}

.quality-assessment__score--4 {
  color: var(--color-score-4-fg);
  background: var(--color-score-4-bg);
}

.quality-assessment__score--5 {
  color: var(--color-score-5-fg);
  background: var(--color-score-5-bg);
}

.quality-assessment__description,
.quality-assessment__submitter {
  margin: 0;
}

.quality-assessment__submitter {
  font-style: italic;
}

.quality-assessment__actions {
  margin: var(--gutter) 0;
  display: flex;
}

.quality-assessment__one-click,
.quality-assessment__delete {
  margin-left: var(--half-gutter);
}
