.card-list__header {
  margin-bottom: 0.75rem;
}

.card-list__cards {
  display: grid;
  grid-template-columns: 100%;
  gap: var(--gutter);
}

@media (--small) {
  .card-list {
    /* Don't want images to get bigger than the biggest possible for medium-up
     * breakpoints. It would require larger image files and the images would
     * take up as much visual space as the main topimage, or even more, in the
     * case of square images
     */
    max-width: 496px;
  }
}

@media (--medium-up) {
  .card-list__header {
    padding-inline: var(--half-gutter);
  }

  .card-list__cards {
    padding-inline: var(--half-gutter);
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (--large-up) {
  .card-list__header {
    margin-bottom: 30px;
  }

  .card-list__cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .card-list__cards--four-col {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  /* This does not match up with the design grid, which has 12 columns here.
   * The exception is used on the theme page, which already had a 5-column
   * variant specified in prod contents (theme_sections.layout). SNL wanted to
   * keep it.
   */
  .card-list__cards--five-col {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}
