.encyclopedia-list__heading {
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
}

.encyclopedia-list__list {
  padding: 0;
  margin: 0;
}

.encyclopedia-list__item {
  list-style: none;
  font: var(--encyclopedia-font);
  text-transform: uppercase;
  margin-bottom: var(--lineheight-body);
}

.encyclopedia-list__link {
  border-bottom-style: none;
}

.encyclopedia-list__link:hover,
.encyclopedia-list__link:focus {
  border-bottom-style: solid;
}
