.button {
  text-decoration: none;
  border: 1px solid black;
  color: var(--blue-dianne);
  background: white;
  font: var(--timestamp);
  text-transform: uppercase;
  display: inline-block;
  padding: 0 10px;
  border-radius: 10px;
  cursor: pointer;
}

.button--selected {
  background: var(--pale-canary);
  cursor: auto;
}

.button--add,
.button--delete {
  padding-left: 20px;
  background-position: 5px;
  background-repeat: no-repeat;
  background-size: 10px;
}

.button--delete {
  background-image: url(../images/delete.svg);
}

.button--add {
  background-image: url(../images/add.svg);
}

.button--disclosure::after {
  content: "▶";
}

.button--disclosure[aria-expanded=true]::after {
  content: "▼";
}

.button:hover,
.button:focus {
  border-bottom-color: black;
}

.button[hidden] {
  display: none;
}

.button[disabled] {
  opacity: 0.5;
  cursor: auto;
}

.button--standalone {
  border-radius: 20px;
}

@media (--small) {
  .button--mobile-icon {
    padding: 0;
    border-style: none;
  }

  .button__icon {
    width: 40px;
    height: 40px;
    vertical-align: top;
  }

  .button--mobile-icon .button__text {
    display: none;
  }
}

@media (--medium-up) {
  .button--standalone {
    padding: 5px 15px;
  }

  .button--add,
  .button--delete {
    padding-left: 35px;
    background-position: 10px;
    background-size: 15px;
  }

  .button__icon {
    display: none;
  }
}
