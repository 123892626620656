.form-field {
  margin-bottom: var(--lineheight-body);
}

.form-field__label {
  font: var(--category);
  display: block;
}

.form-field__error {
  font: var(--meta);
  color: var(--color-guardsman-red);
}

@media (--medium-up) {
  .form-field {
    padding: 0 var(--half-gutter);
    width: calc(4 / 6 * 100%);
  }
}

@media (--medium-plus-up) {
  .form-field {
    display: flex;
    align-items: baseline;
    padding: 0;
    width: 100%;
  }

  .form-field__label {
    width: calc(2 / 10 * 100%);
    padding: 0 var(--half-gutter);
  }

  .form-field__control-wrapper,
  .form-field__help-text {
    width: calc(4 / 10 * 100%);
    padding: 0 var(--half-gutter);
  }

  .form-field__control {
    width: 100%;
  }

  input[type=checkbox].form-field__control {
    width: 1.5em;
    height: 1.5em;
    margin: 0;
  }
}

@media (--large-up) {
  .form-field__label {
    width: calc(2 / 12 * 100%);
    text-align: right;
  }

  .form-field__control-wrapper {
    width: calc(4 / 12 * 100%);
  }

  .form-field__help-text {
    width: calc(6 / 12 * 100%);
  }
}
