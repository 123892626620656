.comment {
  margin: var(--lineheight-body) 0;
}

.comment--reply {
  border-left: 1px solid;
  padding-left: var(--half-gutter);
}

.comment__heading {
  font: var(--tagline);
  text-transform: uppercase;
}

.comment__author {
  border-bottom-style: none;
}

.comment__author:hover,
.comment__author:focus {
  border-bottom-style: solid;
}

.comment__author:link {
  color: var(--alternate-link-color);
  border-bottom-color: var(--alternate-link-underline);
}

.comment__body p {
  margin-bottom: 0;
}

@media (--medium-up) {
  .comment--reply {
    padding-left: var(--gutter);
  }
}
