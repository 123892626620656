.featured-articles__other-items {
  padding: 0;
  margin: 0;
}

.featured-articles__other-item {
  list-style: none;
  margin-bottom: var(--spacing3);
}

@media (--medium-up) {
  .featured-articles__other-items {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: var(--spacing2);
  }

  .featured-articles__other-item {
    margin-bottom: 0;
    padding: 0 var(--half-gutter);
    width: calc(2 / 6 * 100%);
  }

  .featured-articles__main-item {
    padding: 0 var(--half-gutter);
  }

  .featured-articles__main-item:nth-child(2):before {
    content: "";
    display: block;
    border-top: 1px solid;
    margin-bottom: var(--spacing2);
  }
}

@media (--medium-plus-up) {
  .featured-articles {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .featured-articles__other-items {
    padding: 0 var(--half-gutter);
    width: calc(4 / 10 * 100%);
    display: block;
  }

  .featured-articles__other-item:nth-child(n+2) {
    margin-top: var(--spacing3);
  }

  .featured-articles__other-item {
    padding: 0;
    width: auto;
  }

  .featured-articles__main-item {
    width: calc(6 / 10 * 100%);
  }

  .featured-articles__main-item:nth-child(2):before {
    display: none;
  }
}

@media (--large-up) {
  .featured-articles__other-items {
    width: calc(3 / 12 * 100%);
    margin-inline: calc(1 / 12 * 100%);
  }

  .featured-articles__main-item {
    width: calc(6 / 12 * 100%);
    margin-left: calc(1 / 12 * 100%);
  }
}
