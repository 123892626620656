.log-in {
  margin: var(--spacing1) 0 var(--spacing2);
  padding-top: var(--spacing1);
  border-top: 1px solid;
}

.log-in__link {
  display: inline-block;
  border: 2px solid var(--blue-dianne);
  padding: 0.75em var(--half-gutter);
  margin-top: var(--lineheight-body);
  font: var(--subtitle);
  font-variant-numeric: var(--numeric);
}

@media (--medium-up) {
  .log-in {
    margin: var(--spacing2) 0;
    padding-top: 0;
    border-top: none;
  }

  .log-in__link {
    padding-left: var(--gutter);
    padding-right: var(--gutter);
  }
}
