.tabular-fields {
  border: none;
  padding: 0;
  margin: 0 0 var(--lineheight-body);
  min-width: 0;
}

.tabular-fields__legend {
  font: var(--category);
}

.tabular-fields__table-wrapper {
  overflow: auto;
  /* Some space to account for focus outline of "add row" button (else it can
   * be clipped because we are overflow:auto)
   */
  padding-bottom: 2px;
}

.tabular-fields__table {
  width: auto;
  margin-bottom: var(--half-gutter);
}

.tabular-fields__th {
  font: var(--category);
}

.tabular-fields__td {
  border-bottom: none;
  padding-top: var(--half-gutter);
}

.tabular-fields__row {
  opacity: 1;
  transition: opacity 200ms;
}

.tabular-fields__row.is-deleting {
  pointer-events: none;
  opacity: 0;
}

.tabular-fields__input {
  font: inherit;
  padding-inline: 0.25em;
}

@media (--medium-up) {
  .tabular-fields__legend,
  .tabular-fields__table-wrapper {
    padding-inline: var(--half-gutter);
  }
}

@media (--medium-plus-up) {
  .tabular-fields {
    display: flex;
    align-items: center;
  }

  .tabular-fields__legend {
    width: calc(2 / 10 * 100%);
  }

  .tabular-fields__table-wrapper {
    width: calc(8 / 10 * 100%);
  }
}

@media (--large-up) {
  .tabular-fields__legend {
    width: calc(2 / 12 * 100%);
    text-align: right;
  }

  .tabular-fields__table-wrapper {
    width: calc(10 / 12 * 100%);
  }
}
