.hero-map {
  margin: 0 0 var(--spacing2);
  position: relative;
  text-align: center;
  overflow: hidden;
  background: var(--silver);
}

.hero-map__wrapper {
  height: 65vh;
  background-color: var(--color-map-water);
}

.hero-map__popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
  padding: var(--half-gutter);
  border-radius: 10px;
  border: 1px solid var(--silver);
  bottom: 50px;
  left: -135px;
  min-width: 270px;
  text-align: center;
}

.hero-map__popup.hidden {
  display: none;
}

.hero-map__popup:before,
.hero-map__popup:after {
  content: "";
  position: absolute;
  top: 100%;
  width: 0;
  height: 0;
  border: solid transparent;
}

.hero-map__popup:before {
  border-top-color: var(--silver);
  border-width: 11px;
  left: 136px;
  margin-left: -11px;
}

.hero-map__popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 136px;
  margin-left: -10px;
}

.hero-map__popup-closer {
  border: none;
  padding: 0;
  background: transparent;
  position: absolute;
  top: 2px;
  right: 8px;
  font: inherit;
  cursor: pointer;
  display: none;
}

.hero-map__popup.locked .hero-map__popup-closer {
  display: inline;
}

/* Dynamically generated within popup-content */
.hero-map__popup-name {
  font-size: larger;
}

/* Dynamically generated within popup-content */
.hero-map__popup-link {
  font: var(--tagline);
  text-transform: uppercase;
  border: none;
  border-bottom: 1px solid var(--blue-dianne-25);
  padding: 0;
  background: transparent;
  color: inherit;
  cursor: pointer;
}

.hero-map__popup-link:hover,
.hero-map__popup-link:focus {
  border-bottom-color: var(--blue-dianne-75);
}

.hero-map__caption {
  text-align: right;
  font: var(--meta);
  margin: 0 auto;
  padding: 0 var(--half-gutter);
}

.hero-map__zoomed-feature-name {
  font: var(--prioritized);
  text-align: center;
  margin: var(--half-gutter) 0;
}

/* This OpenLayers-generated wrapper comes with z-index: 0 in inline style for
 * some reason, which is a problem because it creates a stacking context that
 * gets placed below the search field and contains the map popup.
 *
 * Here it's forced to 2 instead, so the popup goes on top of the search field.
 */
.hero-map .ol-overlaycontainer-stopevent {
  z-index: 2 !important;
}

/* Move the zoom buttons to the bottom right corner */
.hero-map .ol-zoom {
  top: auto;
  left: auto;
  right: .5em;
  bottom: .5em;
}

.hero-map__location-control {
  top: auto;
  left: auto;
  right: .5em;
  bottom: 4.3em;
}
.hero-map .ol-touch .hero-map__location-control {
  bottom: 5.5em;
}

/* Set cursor on zoom buttons */
.hero-map .ol-zoom-in,
.hero-map .ol-zoom-out {
  cursor: pointer;
}

@media (--large-up) {
  .hero-map__popup {
    min-width: 300px;
    left: -150px;
  }

  .hero-map__popup:before,
  .hero-map__popup:after {
    left: 151px;
  }
}

@media (--xlarge-up) {
  .hero-map__popup {
    min-width: 320px;
    left: -160px;
  }

  .hero-map__popup:before,
  .hero-map__popup:after {
    left: 161px;
  }
}

@media (--xlarge-plus-up) {
  .hero-map__caption {
    max-width: var(--grid-width);
  }
}
