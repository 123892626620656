.factbox {
  background: white;
  padding: var(--gutter) var(--half-gutter);
  font: var(--meta);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing1) var(--gutter);
}

.theme-lille .factbox {
  border: 2px solid var(--color-silver-tree);
}

.factbox__heading,
.factbox__fact-item--comment .factbox__fact-term {
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
}

.factbox__main-content {
  grid-column: 1 / span 2;
}

.factbox__subheading,
.factbox__lede,
.factbox__facts {
  margin: 0;
}

.factbox__subheading,
.factbox__fact-term {
  display: inline;
  font: var(--tagline);
  text-transform: uppercase;
}

.factbox__fact-description {
  display: inline;
  margin-left: 0;
}

.factbox__fact-item--comment .factbox__fact-description {
  font-style: italic;
}

.factbox__fact-item--pronunciation .factbox__fact-description em {
  font-style: normal; 
  text-decoration: underline; 
  text-decoration-thickness: 0.9pt; 
  text-underline-offset: 0.25em;  
}

/* Hack: see https://github.com/SNL-AS/snl/issues/391 */
/* Desired behavior as per discussion with Anne@SNL: dt and dd on same line
 * (wrap as necessary) if one paragraph, on separate lines if multiple
 */
.factbox__lede div,
.factbox__lede p,
.factbox__fact-description div,
.factbox__fact-description p {
  margin: 0;
}
.factbox__fact-description div:only-child,
.factbox__fact-description p:only-child {
  display: inline;
}

.factbox__flag,
.factbox__coat-of-arms {
  margin: 0;
}

.factbox--municipal .factbox__coat-of-arms {
  grid-column: 1 / span 2;
  display: flex;
  align-items: flex-start;
}

.factbox__flag-image,
.factbox__coat-of-arms-image {
  width: auto;
  height: auto;
  max-width: 100%;
  /* This value is intended to limit the extent to which a portrait
   * coat-of-arms might become taller than a landscape flag beside it. Ideally
   * they would have equal height, but this is tricky given figcaptions and
   * the need to limit width without losing intrinsic aspect ratio
   */
  max-height: 110px;
}

.factbox--municipal .factbox__coat-of-arms-image {
  max-width: 50%;
  max-height: 200px;
  flex: none;
}

.factbox--municipal .factbox__coat-of-arms-caption {
  padding-left: var(--half-gutter);
}

@media (--medium-up) {
  .factbox {
    padding: var(--gutter);
  }

  .factbox--large {
    grid-template-columns: repeat(6, 1fr);
    padding: var(--gutter) 0;
  }

  .factbox--large .factbox__main-content {
    grid-column: 1 / span 4;
    padding-left: var(--gutter);
  }

  .factbox--large .factbox__flag,
  .factbox--large .factbox__coat-of-arms {
    grid-column: 5 / span 2;
    padding-right: var(--gutter);
  }

  .factbox--small .factbox__flag,
  .factbox--small .factbox__coat-of-arms {
    grid-column: 1 / span 2;
  }

  .factbox--municipal .factbox__coat-of-arms {
    grid-column: 1 / span 6;
    padding-left: var(--gutter);
  }
}

@media (--medium-plus-up) {
  .factbox--large {
    grid-template-columns: repeat(10, 1fr);
  }

  .factbox--large .factbox__main-content {
    grid-column: 1 / span 6;
    padding-right: var(--gutter);
    columns: 2;
    column-gap: var(--gutter);
  }

  .factbox--large .factbox__fact-item {
    break-inside: avoid; /* Most browsers don't support avoid-column */
    page-break-inside: avoid; /* Firefox which doesn't support break-inside */
  }

  .factbox--large .factbox__flag {
    grid-column: 7 / span 2;
    padding-right: 0;
  }

  .factbox--large .factbox__coat-of-arms {
    grid-column: 9 / span 2;
  }

  .factbox--municipal .factbox__coat-of-arms {
    grid-column: 7 / span 4;
    padding-left: 0;
  }
}

@media (--large-up) {
  .factbox {
    padding: var(--gutter);
  }

  .factbox--large {
    padding: var(--gutter) 0;
  }

  .factbox--large .factbox__main-content {
    padding: 0 var(--gutter);
  }

  .factbox--small .factbox__flag,
  .factbox--small .factbox__coat-of-arms {
    grid-column: auto;
  }
}
