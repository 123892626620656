.toggle-state__button {
  text-decoration: none;
  border: 1px solid black;
  background: var(--blue-dianne);
  color: var(--black-haze);
  font: var(--timestamp);
  text-transform: uppercase;
  display: inline-block;
  padding: 0 10px 0 0;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
}

.toggle-state__button:before {
  content: "?";
  display: inline-block;
  background-color: var(--straw);
  color: var(--blue-dianne);
  border-radius: 50%;
  width: 20px;
  margin-right: 10px;
}

.toggle-state__button:focus {
  outline-color: var(--blue-dianne);
}

.toggle-state__button:hover:after {
  content: attr(data-change-text);
  position: absolute;
  top: 0;
  left: 30px;
  right: 10px;
  bottom: 0;
  background-color: var(--blue-dianne);
  text-align: left;
}

/* TODO: Generic states, not these comment-specific ones */
.toggle-state__button--pending:before {
  content: "?";
}

.toggle-state__button--approved:before {
  background-color: var(--color-silver-tree);
  content: "√";
}

.toggle-state__button--denied:before {
  background-color: var(--petite-orchid);
  content: "×";
}
