.toc {
  font: var(--small-body);
  overflow: auto; /* In case of long words; SNL don't want to hyphenate/wrap */
}

.theme-lille .toc {
  font: var(--body);
}

.theme-lex .toc {
  font: var(--meta);
}

.toc__list {
  padding: 0;
}

.toc__item {
  list-style: none;
  border-bottom: 1px solid;
  text-wrap: balance;
}

.theme-lille .toc__item {
  border-bottom-color: var(--color-silver-tree);
  border-bottom-width: 1.5px;
}

.toc__link,
.toc__sub-link {
  display: block;
  border-bottom-style: none;
}

.toc__link {
  padding: 2px 0 5px; /* Align to first line of main body in some situations */
}

.theme-lille .toc__link {
  padding: 8px 0;
}

.toc__sub-list {
  padding: 0;
  margin: 0;
}

.toc__sub-item {
  list-style: none;
}

.toc__sub-link {
  padding-bottom: 5px;
  display: flex;
}

.toc__sub-link:before {
  content: "→ ";
  font-size: 0.75em;
  vertical-align: 0.125em;
  margin-right: 0.5em;
}

@media (--xlarge-up) {
  .toc__link {
    padding-top: 3px; /* Align to first line of main body in some situations */
  }
}
