.link-button {
  border: none;
  border-bottom: 1px solid var(--blue-dianne-25);
  padding: 0;
  background: transparent;
  color: inherit;
  font: inherit;
  cursor: pointer;
}

.link-button:hover,
.link-button:focus {
  border-bottom-color: var(--blue-dianne-75);
}

.link-button__extra-text {
  display: none;
}

@media (--medium-plus-up) {
  .link-button__extra-text {
    display: inline;
  }
}
