.tagsonomy-affiliations {
  border-width: 1px;
  border-style: solid none;
  padding: var(--lineheight-body) 0;
  margin-bottom: var(--spacing1);
  font: var(--prioritized);
}

.tagsonomy-affiliations__heading {
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
}

.tagsonomy-affiliations__link {
  font: var(--subtitle);
  font-variant-numeric: var(--numeric);
  border-bottom-style: none;
}

.tagsonomy-affiliations__link:hover,
.tagsonomy-affiliations__link:focus {
  border-bottom-style: solid;
}

.tagsonomy-affiliations__link:link {
  color: var(--alternate-link-color);
  border-bottom-color: var(--alternate-link-underline);
}
