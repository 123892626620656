.page-title {
  overflow: auto; /* In case of long words; SNL don't want to hyphenate/wrap */
  text-wrap: balance;
}

.theme-lille .page-title {
  color: var(--color-eucalyptus);
}

.page-title__prefix {
  font: var(--category);
  display: block;
}

.page-title__clarification {
  /* Treated as a special case, no appropriate "standard" font exists.
   * See https://github.com/SNL-AS/snl/pull/1363
   */
  font-size: 75%;
  font-weight: normal;
}

@media (--medium-up) {
  .page-title--on-image,
  .theme-lex .page-title {
    font: var(--title-large);
    font-variant-numeric: var(--numeric);
  }

  .page-title--on-dark-image {
    color: white;
  }

  .theme-lille .page-title--on-image {
    font: var(--title);
    font-variant-numeric: var(--numeric);
  }
}
