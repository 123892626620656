.l-article-series {
  margin-top: var(--spacing1);
  padding: 0 var(--half-gutter);
  min-height: 50vh;
}

.l-article-series__main {
  margin: var(--spacing2) 0 var(--spacing3);
}

@media (--medium-up) {
  .l-article-series {
    padding: 0 var(--half-gutter);
    width: var(--grid-width);
    margin-inline: auto;
  }

  .l-article-series__main {
    padding-left: var(--half-gutter);
    padding-right: var(--half-gutter);
    width: calc(4 / 6 * 100%);
  }
}

@media (--medium-plus-up) {
  .l-article-series__main {
    width: calc(6 / 10 * 100%);
  }
}

@media (--large-up) {
  .l-article-series__main {
    width: calc(6 / 12 * 100%);
    margin-left: calc(2 / 12 * 100%);
  }
}
