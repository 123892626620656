.l-footer {
  padding-bottom: var(--spacing2);
}

.theme-snl .l-footer {
  background: var(--turquoise-blue);
}

.theme-lex .l-footer {
  background: var(--color-alto);
  padding-bottom: 0;
}

.theme-lille .l-footer {
  background: var(--color-silver-tree);
  --visited-color: var(--link-color);
  --visited-underline: var(--link-underline);
  --visited-underline-focus: var(--link-underline-focus);
}

.l-footer__reading-tips {
  padding: 0;
  margin: 0;
}

.l-footer__reading-tip {
  list-style: none;
  padding: var(--spacing2) 0;
  border-top: 1px solid;
}

.l-footer__reading-tip:first-child {
  border-top: none;
}

.l-footer__heading {
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
}

.l-footer__about-encyclopedia {
  padding-top: var(--spacing2);
  margin-top: var(--spacing2);
  border-top: 1px solid;
}

.l-footer__base:before {
  content: "";
  display: block;
  border-top: 1px solid;
  margin: 0 0 var(--spacing1);
  width: 100%;
}

.l-footer__logos {
  padding-top: var(--spacing2);
  margin-top: var(--spacing2);
  border-top: 1px solid;
}

.theme-lex .l-footer__logos {
  border-bottom: 1px solid;
}

@media (--medium-up) {
  .l-footer__base {
    display: flex;
    flex-wrap: wrap;
  }

  .l-footer__reading-tips,
  .l-footer__updates,
  .l-footer__about {
    padding: 0 var(--half-gutter);
  }

  .l-footer__about-encyclopedia {
    margin: var(--spacing2) var(--half-gutter) 0;
  }

  .l-footer__base:before {
    margin: 0 var(--half-gutter) var(--spacing1);
  }

  .l-footer__updates {
    width: calc(3 / 6 * 100%);
  }

  .l-footer__about {
    width: calc(3 / 6 * 100%);
    padding-left: calc(var(--half-gutter) + var(--gutter));
    position: relative;
  }

  .l-footer__about:before {
    content: "";
    position: absolute;
    left: var(--half-gutter);
    top: 0;
    bottom: 0;
    border-left: 1px solid;
  }

  .l-footer__logos {
    margin: var(--spacing2) var(--half-gutter) 0;
  }

  .l-footer__copyright {
    margin: 0 var(--half-gutter);
  }
}

@media (--medium-plus-up) {
  .l-footer__reading-tips {
    width: calc(8 / 10 * 100%);
  }

  .l-footer__updates {
    width: calc(6 / 10 * 100%);
  }

  .l-footer__about {
    width: calc(4 / 10 * 100%);
  }
}

@media (--large-up) {
  .l-footer__reading-tips {
    width: calc(8 / 12 * 100%);
    margin-left: calc(2 / 12 * 100%);
  }

  .l-footer__about-encyclopedia {
    padding: var(--spacing2) 0 0 calc(1 / 12 * 100%);
    margin: var(--spacing2) var(--half-gutter) 0;
  }

  .l-footer__updates {
    width: calc(6 / 12 * 100%);
    margin-left: calc(1 / 12 * 100%);
  }

  .l-footer__about {
    margin-left: calc(1 / 12 * 100%);
    padding-left: var(--half-gutter);
    width: calc(4 / 12 * 100%);
  }

  .l-footer__about:before {
    left: calc(-1 * var(--half-gutter));
  }

  .l-footer__logos {
    padding: var(--spacing2) calc(1 / 12 * 100%) 0;
  }

  .l-footer__logos--large {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (--xlarge-plus-up) {
  .l-footer__about {
    width: calc(3 / 12 * 100%);
    margin-right: calc(1 / 12 * 100%);
  }
}
