.l-taxonomy {
  margin-top: var(--spacing1);
  margin-bottom: var(--spacing4);
}

.l-taxonomy--map {
  margin-top: 0;
}

.l-taxonomy__intro,
.l-taxonomy__credit,
.l-taxonomy__article-list {
  margin-bottom: var(--spacing1);
}

.l-taxonomy__main:before {
  content: "";
  display: block;
  border-top: 1px solid;
  margin: 0 0 var(--spacing1);
}

.l-taxonomy__contents {
  margin-bottom: var(--spacing3);
}

.l-taxonomy__sub-taxonomies {
  border-bottom: 1px solid;
  margin-bottom: var(--spacing1);
}

@media (--medium-up) {
  .l-taxonomy__intro,
  .l-taxonomy__credits {
    padding-left: var(--half-gutter);
    padding-right: var(--half-gutter);
    width: calc(4 / 6 * 100%);
  }

  .l-taxonomy__main:before {
    margin: 0 var(--half-gutter) var(--spacing1);
  }

  .l-taxonomy__contents,
  .l-taxonomy__activity {
    padding: 0 var(--half-gutter);
  }
}

@media (--medium-plus-up) {
  .l-taxonomy__header {
    display: flex;
    align-items: flex-end;
  }

  .l-taxonomy__intro {
    width: calc(6 / 10 * 100%);
  }

  .l-taxonomy__credits {
    width: calc(4 / 10 * 100%);
  }
}

@media (--large-up) {
  .l-taxonomy__intro {
    width: calc(6 / 12 * 100%);
    margin-left: calc(2 / 12 * 100%);
  }

  .l-taxonomy__credits {
    width: calc(4 / 12 * 100%);
  }

  .l-taxonomy__main:after {
    content: "";
    display: block;
    clear: both;
  }

  .l-taxonomy__contents {
    width: calc(10 / 12 * 100%);
    float: right;
    margin-bottom: 0;
  }

  .l-taxonomy__activity {
    width: calc(2 / 12 * 100%);
    float: left;
    margin-bottom: 0;
    overflow: auto; /* In case of long words; SNL don't want to hyphenate/wrap */
  }
}
