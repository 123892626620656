.alarm {
  background-color: red;
  color: black;
  animation: blink 1s steps(2, start) infinite;
}

.alarm__message {
  margin: 0;
}

@media (--medium-up) {
  .alarm__message {
    padding: 0 var(--half-gutter);
  }
}
