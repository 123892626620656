.search-form {
  --search-button-width: 50px;
  position: relative;
}

.theme-lex .search-form {
  --search-button-width: 48px;
  padding-right: 53px;
}

.search-form__field {
  font: var(--prioritized);
  padding: 12px;
  padding-right: var(--search-button-width);
  border: 1px solid var(--color-dusty-gray);
  border-bottom: 3px solid;
  background: white;
  width: 100%;
  /* Hack for Safari. With default appearance, Safari on macOS refuses to apply
   * the padding/etc styles. Additionally, Safari on iOS insists on using
   * rounded corners even with -webkit-appareance: textfield (which would
   * otherwise be sufficient for macOS Safari).
   */
  -webkit-appearance: none;
  /* And then also this to override iOS Safari's UA stylesheet */
  border-radius: 0;
}

.theme-snl .search-form__field {
  border-bottom-color: var(--bright-turquoise);
}

.theme-lille .search-form__field {
  border-color: var(--color-sea-green);
  border-bottom-width: 1px;
}

.theme-lille .search-form__field:not(.search-form__field--front) {
  padding-block: 8px; /* Intentionally applying across all breakpoints */
}

.theme-lex .search-form__field {
  font: var(--search-font);
  padding: 10px 20px 11px 15px;
  border-bottom-color: black;
  border-bottom-width: 2px;
}

.search-form__button {
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  width: var(--search-button-width);
  height: 100%;
  cursor: pointer;
}

.theme-lex .search-form__button {
  background: black;
}

.search-form__icon {
  width: 24px;
  height: auto;
}

.search-form__autocomplete-item {
  list-style: none;
  background: white;
  padding: var(--half-gutter);
  cursor: pointer;
}

.search-form__autocomplete-item:hover,
.search-form__autocomplete-item[aria-selected=true] {
  background: var(--humming-bird);
}

.search-form__autocomplete-item:nth-child(n+2) {
  border-top: 1px solid;
}

.theme-lille .search-form__autocomplete-item {
  border: 1px solid var(--color-sea-green);
  border-top-style: none;
}

.theme-lille .search-form__autocomplete-item:hover,
.theme-lille .search-form__autocomplete-item[aria-selected=true] {
  background: var(--black-haze);
}

.search-form__autocomplete-title {
  font: var(--subtitle);
  font-variant-numeric: var(--numeric);
  color: var(--alternate-link-color);
}

.search-form__autocomplete-encyclopedia {
  font: var(--tagline);
  text-transform: uppercase;
}

.search-form__autocomplete-excerpt {
  margin: 0;
  font: var(--small-body);
}

.search-form__autocomplete-excerpt:after {
  content: "…";
}


@media (--medium-up) {
  /* Not for .theme-lex */
  .search-form {
    --search-button-width: calc(50px + 2 * var(--gutter));
  }

  .search-form__field {
    padding: 15px var(--search-button-width) 15px var(--gutter);
  }

  .search-form__autocomplete-item {
    padding: var(--gutter);
  }
}

@media (--large-up) {
  .search-form__field {
    padding-top: 22px;
    padding-bottom: 22px;
  }

  .search-form__icon {
    width: 30px;
  }

  .theme-lex .search-form__icon {
    width: 24px;
  }
}

@media (--xlarge-up) {
  .search-form__field {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .search-form__icon {
    width: 35px;
  }
}
