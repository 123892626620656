.autocomplete-field {
  position: relative;
}

.autocomplete-field__input {
  width: 100%;
  font: inherit;
  padding: 2px 0.25em;
  border: 1px solid;
}

.autocomplete-field__autocomplete-item {
  list-style: none;
  background: white;
  padding: var(--half-gutter);
  cursor: pointer;
  border: 1px solid var(--color-dusty-gray);
  border-top-style: none;
}

.autocomplete-field__autocomplete-item:hover,
.autocomplete-field__autocomplete-item[aria-selected=true] {
  background: var(--humming-bird);
}

.autocomplete-field__autocomplete-item[aria-selected=true] {
  outline: 1px solid;
}

.autocomplete-field__autocomplete-excerpt {
  display: none;
}
