.user-menu {
  font: var(--tagline);
  text-transform: uppercase;
  background: white;
  padding: var(--half-gutter) 0;
}

.user-menu__contents {
  display: flex;
  align-items: center;
}

.user-menu__items {
  flex: auto;
  padding: 12px 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.user-menu__item {
  list-style: none;
  margin-right: var(--half-gutter);
}

.user-menu__item-link,
.user-menu__profile-link {
  border-bottom-style: none;
}

.user-menu__item-link:hover {
  border-bottom-style: solid;
}

.user-menu__logout {
  font: inherit;
  text-transform: uppercase; /* Override Chrome UA stylesheet */
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;
}

.user-menu__logout:hover {
  border-bottom: 1px solid var(--blue-dianne-75);
}

.user-menu__user-name {
  display: none;
}

.user-menu__profile-link:hover .user-menu__user-name {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: inherit;
}

.user-menu__user-image {
  width: 40px;
  height: 40px;
  vertical-align: top;
  border-radius: 50%;
  margin: 2px 0;
}

@media (--medium-up) {
  .user-menu__items,
  .user-menu__profile-link {
    padding: 0 var(--half-gutter);
  }

  .user-menu__profile-link {
    flex: none;
    display: flex;
    align-items: center;
  }

  .user-menu__user-name {
    display: inline;
    margin-right: var(--half-gutter);
  }
}

@media (--large-up) {
  .user-menu__item {
    margin-right: var(--gutter);
  }
}

@media (--xlarge-up) {
  .user-menu__user-image {
    width: 50px;
    height: 50px;
  }
}
