.chart {
  margin: 0;
  background: white;
  font: var(--meta);
  padding: var(--half-gutter);
}

.theme-lille .chart {
  border-block: 2px solid var(--color-silver-tree);
}

.chart__heading {
  margin-bottom: 0.5rem;
}

.chart__thead {
  font: var(--meta-bold);
}

.chart__line-container {
  aspect-ratio: 1.618;
}

.chart__caption {
  font: var(--meta);
  margin-top: 0.5rem;
}

@media (--medium-up) {
  .theme-lille .chart {
    border: 2px solid var(--color-silver-tree);
  }
}
