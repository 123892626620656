/* Styling for non-configurable Awesomplete elements */
.awesomplete > ul {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1;
}

.awesomplete > .visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
}
