.l-media-edit-order {
  margin-bottom: var(--gutter);
}

.l-media-edit-order__drop-target {
  min-height: var(--spacing2);
}

.l-media-edit-order__drop-target--drop-above {
  border-top: 4px solid var(--allports-75);
}

.l-media-edit-order__drop-target--drop-below {
  border-bottom: 4px solid var(--allports-75);
}

.l-media-edit-order__drop-target--drop-inside {
  background-color: var(--allports-75);
}
