.article-text {
  /* In the rare case of very long words (see e.g.
   * https://wja.slack.com/archives/C02FY7L96/p1733404055799039
   */
  overflow-wrap: break-word;
}

.theme-lille .article-text {
  font: var(--large-body);
}

/* Should normally not use type selectors, but making backend add classes to
 * the article contents is not trivial. This is safe as long as .article-text
 * doesn't embed other components.
 */
.article-text li {
  text-wrap: balance;
}

/* Ideally we'd do a bit more here and probably also have a wrapper around
 * the table (so we can still style the actual table object if need be), but
 * this is the technique used for mobile in the old design
 */
.article-text table {
  display: block;
  overflow: auto;
}

.theme-snl .article-text thead tr {
  border-bottom: 2px solid var(--turquoise-blue);
}

.theme-lex .article-text thead tr {
  border-bottom: 2px solid var(--color-midnight-blue);
}

.theme-lille .article-text thead tr {
  border-bottom: 2px solid var(--color-silver-tree);
}

.article-text th,
.article-text td {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  font: var(--meta);
  font-variant-numeric: tabular-nums;
  vertical-align: top;
  text-wrap: balance;
}

.article-text thead th {
  color: var(--allports);
  font-weight: bold;
}

.theme-lille .article-text thead th {
  color: var(--color-plantation);
}

.article-text tbody th,
.article-text tbody td {
  border-top: 1px solid var(--silver);
}

/* SNL currently may use generated content type "html" to inject a figure with
 * a table in it, to create "footnotes" in the figcaption. Until we are able to
 * support this footnote usecase more properly, the following figure/figcaption
 * styles attempt to make that generated_content hack look OK.
 */
.article-text figure {
  margin: 0;
}

.article-text figcaption {
  font: var(--meta);
  margin-bottom: var(--lineheight-body);
}

.article-text figcaption p {
  margin-bottom: 0.5em;
}

/* Stopgap solution to allow labelling cells as "numeric" and get right-aligned
 * text, again via "html" generated content, as above. A more proper solution
 * should ensure we serialize to classes that are controlled by the app itself,
 * on the "standard" format (e.g. ".article-text__table-cell--numeric"). When
 * browser support allows, it could then even be converted to set the class on
 * the <col> level and use the || column combinator in CSS.
 */
.article-text th.numeric,
.article-text td.numeric {
  text-align: right;
}

.article-text__generated-content-text {
  border: dashed 1px;
  font-style: italic;
  padding: var(--half-gutter);
}

.article-text__generated-content-block {
  margin: var(--spacing1) 0;
}
