.gallery {
  padding: 0;
}

.theme-lille .gallery {
  border: 2px solid var(--color-silver-tree);
}

.gallery__item {
  list-style: none;
}

@media (--small) {
  .gallery__item {
    margin-bottom: var(--half-gutter);
  }
}

@media (--medium-up) {
  .gallery {
    background: white;
    display: flex;
    padding-right: var(--half-gutter);
    padding-bottom: var(--half-gutter);
    flex-wrap: wrap;
  }

  .gallery__item {
    padding-top: var(--half-gutter);
    padding-left: var(--half-gutter);
    flex: 0 0 auto;
  }
}
