.image-teaser {
  position: relative;
  z-index: 0;
}

.image-teaser::after {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.4) 100%);
}

.image-teaser--light-text::after {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.4) 100%);
}

.image-teaser__text {
  position: absolute;
  left: var(--half-gutter);
  right: var(--half-gutter);
  bottom: var(--half-gutter);
  z-index: 1;
  text-shadow: 1px 1px 1px white;
}

.image-teaser--light-text .image-teaser__text {
  text-shadow: 1px 1px 1px black;
}

.image-teaser__tagline {
  font: var(--tagline);
  text-transform: uppercase;
  margin-bottom: 0;
}

.image-teaser--light-text .image-teaser__tagline {
  color: white;
}

.image-teaser__heading {
  font: var(--title);
  font-variant-numeric: var(--numeric);
}

.image-teaser__link {
  border-bottom-style: none;
}

.image-teaser__link:hover,
.image-teaser__link:focus {
  border-bottom-style: solid;
}

.image-teaser--light-text .image-teaser__link:link,
.image-teaser--light-text .image-teaser__link:visited {
  color: white;
  border-color: white;
}

.image-teaser__figure {
  margin: 0;
}

.image-teaser__image {
  width: 100%;
  vertical-align: top;
}

@media (--medium-plus-up) {
  .image-teaser__text {
    left: var(--gutter);
    right: var(--gutter);
    bottom: var(--gutter);
  }
}
