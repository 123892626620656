.encyclopedia-copyright {
  font: var(--small-body);
}

@media (--medium-up) {
  .encyclopedia-copyright {
    text-align: center;
  }

  .encyclopedia-copyright__editor,
  .encyclopedia-copyright__work {
    display: block;
  }
}
