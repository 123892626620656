.home-link {
  padding: var(--lineheight-body) 0;
  color: var(--blue-dianne);
  font: var(--logo-font);
  text-transform: uppercase;
  text-align: center;
}

.theme-lille .home-link,
.theme-snl .home-link {
  background: var(--turquoise-blue);
}

.home-link__link {
  border-bottom-style: none;
  color: inherit; /* Drop separate :visited color */
}

.home-link__link:hover,
.home-link__link:focus {
  border-bottom-style: solid;
}
