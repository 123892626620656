.style-guide {
  position: relative;
  z-index: 0;
}

.style-guide--grid:before {
  content: "";
  position: fixed;
  z-index: 1;
  top: 0;
  height: 100%;
  pointer-events: none;
}

.style-guide__section {
  margin: 2em 0;
}

.style-guide__section-heading {
  background: black;
  color: white;
  padding: 1em;
  font-size: 1.5rem;
}

.style-guide__section-content {
  padding: 2em 0;
  color: var(--body-foreground);
  background: var(--body-background);
}

.style-guide__section-content--dark {
  background: darkgray;
}

@media (--small) {
  .style-guide--grid:before {
    left: 24px;
  }

  .style-guide--grid:after {
    content: "";
    position: fixed;
    z-index: 1;
    top: 0;
    height: 100%;
    pointer-events: none;
    right: 24px;
  }

  .style-guide--grid:before,
  .style-guide--grid:after {
    width: calc(50% - 24px - 12px);
    border: 1px solid coral;
    border-style: none solid;
    outline: 1px solid coral;
    outline-offset: 12px;
    background-size: 100%;
  }
}

@media (--medium-up) {
  .style-guide--grid:before {
    left: 50%;
    width: calc(6 * 92px + 5 * 24px);
    margin-left: calc(-0.5 * (6 * 92px + 5 * 24px));
    background: linear-gradient(to right, coral 0, coral 1px, transparent 1px, transparent 91px, coral 91px, coral 92px, transparent 92px);
    background-size: calc(92px + 24px);
  }
}

@media (--medium-plus-up) {
  .style-guide--grid:before {
    width: calc(10 * 65px + 9 * 24px);
    margin-left: calc(-0.5 * (10 * 65px + 9 * 24px));
    background: linear-gradient(to right, coral 0, coral 1px, transparent 1px, transparent 64px, coral 64px, coral 65px, transparent 65px);
    background-size: calc(65px + 24px);
  }
}

@media (--large-up) {
  .style-guide--grid:before {
    width: calc(12 * 72px + 11 * 28px);
    margin-left: calc(-0.5 * (12 * 72px + 11 * 28px));
    background: linear-gradient(to right, coral 0, coral 1px, transparent 1px, transparent 71px, coral 71px, coral 72px, transparent 72px);
    background-size: calc(72px + 28px);
  }
}

@media (--xlarge-up) {
  .style-guide--grid:before {
    width: calc(12 * 85px + 11 * 28px);
    margin-left: calc(-0.5 * (12 * 85px + 11 * 28px));
    background: linear-gradient(to right, coral 0, coral 1px, transparent 1px, transparent 84px, coral 84px, coral 85px, transparent 85px);
    background-size: calc(85px + 28px);
  }
}

@media (--xlarge-plus-up) {
  .style-guide--grid:before {
    width: calc(12 * 103px + 11 * 28px);
    margin-left: calc(-0.5 * (12 * 103px + 11 * 28px));
    background: linear-gradient(to right, coral 0, coral 1px, transparent 1px, transparent 102px, coral 102px, coral 103px, transparent 103px);
    background-size: calc(103px + 28px);
  }
}
