.taxonomy-list__heading {
  font: var(--tagline);
  text-transform: uppercase;
}

.taxonomy-list__items {
  padding: 0;
}

.taxonomy-list__item {
  list-style: none;
  font: var(--prioritized);
  padding: 0 0 0.75em;
}

.taxonomy-list__link {
  border-bottom-style: none;
}

.taxonomy-list__link:hover,
.taxonomy-list__link:focus {
  border-bottom-style: solid;
}

.taxonomy-list__link:link {
  color: var(--alternate-link-color);
  border-bottom-color: var(--alternate-link-underline);
}

@media (--medium-up) {
  .taxonomy-list__item {
    display: inline-block;
    padding: 0 0.75em 0.25em 0;
  }
}
