.about-encyclopedia__heading {
  font: var(--subtitle);
  font-variant-numeric: var(--numeric);
}

.about-encyclopedia__part {
  font: var(--small-body);
}

.about-encyclopedia__part img {
  width: 50%;
}

@media (--large-up) {
  .about-encyclopedia__parts {
    display: flex;
    margin-top: 10px;
  }

  .about-encyclopedia__part {
    width: calc(1 / 3 * 100%);
    padding-right: var(--gutter);
  }

  .about-encyclopedia--small .about-encyclopedia__part {
    width: calc(1 / 2 * 100%);
  }
}
