.l-article-menu {
  background-color: var(--black-haze);
  border-bottom: 2px solid white;
}

.l-article-menu__edit-links,
.l-article-menu__quality {
  padding: var(--half-gutter) 0;
}

.l-article-menu__notice {
  margin-bottom: var(--half-gutter);
}

@media (--medium-up) {
  .l-article-menu__contents {
    display: flex;
    flex-wrap: wrap;
  }

  .l-article-menu__edit-links,
  .l-article-menu__quality,
  .l-article-menu__stats,
  .l-article-menu__chart {
    padding: var(--half-gutter);
  }

  .l-article-menu__edit-links {
    width: 100%;
  }

  .l-article-menu__quality {
    width: calc(3 / 6 * 100%);
  }

  .l-article-menu__stats,
  .l-article-menu__chart {
    width: calc(1.5 / 6 * 100%);
  }

  .l-article-menu__notice {
    margin-top: var(--half-gutter);
  }
}

@media (--medium-plus-up) {
  .l-article-menu__contents {
    flex-wrap: nowrap;
  }

  .l-article-menu__edit-links {
    width: calc(4 / 10 * 100%);
  }

  .l-article-menu__quality {
    width: calc(3 / 10 * 100%);
  }

  .l-article-menu--only-edit-links .l-article-menu__edit-links {
    width: auto;
    border-right: none;
  }

  .l-article-menu__stats,
  .l-article-menu__chart {
    width: calc(1.5 / 10 * 100%);
  }
}

@media (--large-up) {
  .l-article-menu__edit-links {
    width: calc(5 / 12 * 100%);
    border-right: 2px solid white;
  }

  .l-article-menu__quality {
    width: calc(3 / 12 * 100%);
  }

  .l-article-menu__stats,
  .l-article-menu__chart {
    width: calc(2 / 12 * 100%);
  }
}
