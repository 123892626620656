.overview__heading {
  margin-bottom: 0.75rem;
  font: var(--tagline);
  text-transform: uppercase;
}

.theme-lex .overview--taxonomies .overview__heading {
  font: var(--front-subheading);
  text-transform: none;
}

.overview__list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.overview__item {
  font: var(--meta);
}

.theme-lex .overview--taxonomies .overview__item {
  font: var(--home-link-font);
}

.theme-lille .overview__item {
  font: var(--body);
}

.overview__link {
  border-bottom-style: none;
  padding: 10px;
  padding-right: 44px;
  background: url('../images/arrow-down.svg') right 10px center no-repeat white;
  color: var(--alternate-link-color);
  display: block;
  max-width: max-content;
}

.theme-lex .overview__link {
  color: var(--link-color);
}

.theme-lille .overview__link {
  padding-right: 10px;
  background: var(--color-silver-tree);
  color: inherit;
  font: var(--subtitle);
}

.theme-lille .overview__link::after {
  content: " ↓";
  font: var(--arrow-font);
}

.theme-lex .overview--taxonomies .overview__link {
  background-image: url('../images/arrow-right.svg');
  background-color: var(--color-periwinkle);
}

.overview__link:hover,
.overview__link:focus {
  text-decoration: underline;
}

.theme-lille .overview__link:hover,
.theme-lille .overview__link:focus {
  text-decoration-thickness: 1px;
  text-underline-offset: 0.2em;
}

/* Ad-hoc media query to trigger slightly earlier than medium-up */
@media (min-width: 600px) {
  .overview__list {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media (--medium-up) {
  .overview--taxonomies .overview__heading {
    margin-bottom: 30px;
  }
}

@media (--large-up) {
  .overview--taxonomies .overview__link {
    padding-right: 79px;
    padding-bottom: 44px;
    background-position: left 10px bottom 10px;
  }
}
