.advisor {
  font: var(--meta);
  display: flex;
  flex-direction: row-reverse;
  gap: var(--half-gutter);
}

.advisor__text {
  flex: 1;
}

.advisor__heading {
  font: var(--tagline);
  text-transform: uppercase;
}

.advisor__figure,
.advisor__name,
.advisor__institution {
  margin: 0;
}

.advisor__figure {
  width: 78px;
}

.advisor__image {
  max-width: 100%;
  vertical-align: top;
}
