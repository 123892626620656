.hero-image {
  margin: 0 0 var(--spacing2);
  position: relative;
  text-align: center;
  overflow: hidden;
  background: var(--silver);
}

.hero-image__image {
  display: inline-block; /* Workaround for Chromium when the image is unavailable */
  height: 300px;
  vertical-align: top;
  /* Would use object-fit, but IE11 doesn't support that */
  margin-left: 50%;
  transform: translateX(-50%);
}

.hero-image__caption {
  text-align: right;
  font: var(--meta);
  margin: 0 auto;
  padding: 0 var(--half-gutter);
}

.hero-image__extra-description {
  display: none;
}

.hero-image__attribution-icon {
  height: 1em;
  vertical-align: -0.15em;
}

.hero-image__link {
  font: var(--tagline);
  text-transform: uppercase;
  border-bottom-style: none;
  white-space: nowrap;
}

.hero-image__link:hover,
.hero-image__link:focus {
  border-bottom-style: solid;
}

@media (--small) {
  .hero-image {
    z-index: 0;
  }

  .hero-image:before {
    content: "";
    position: absolute;
    top: 0;
    height: 30%;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, black 0%, transparent 100%);
    z-index: 1;
  }

  .hero-image--dark-text:before {
    background: linear-gradient(to bottom, white 0%, transparent 100%);
  }
}

@media (--medium-up) {
  .hero-image__image {
    height: 450px;
  }
}

@media (--large-up) {
  .hero-image__image {
    height: 600px;
  }

  .hero-image__caption {
    padding: 0 var(--half-gutter);
  }

  .hero-image__extra-description {
    display: inline;
  }
}

@media (--xlarge-plus-up) {
  .hero-image__caption {
    max-width: var(--grid-width);
  }
}
