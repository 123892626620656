:root {
  /* Color names from http://chir.ag/projects/name-that-color/ */
  --allports: #0077a5;
  --allports-75: rgba(0, 118, 165, 0.75);
  --black-haze: #f3f4f4;
  --blue-dianne: #203e51;
  --blue-dianne-25: rgba(37, 62, 80, 0.25);
  --blue-dianne-75: rgba(34, 65, 78, 0.75);
  --bright-turquoise: #0cc0eb;
  --humming-bird: #cdf7f9;
  --link-water: #deedf5;
  --petite-orchid: #dd9594;
  --pale-canary: #ffff99;
  --silver: #ccc;
  --straw: #d0cd7d;
  --turquoise-blue: #56d3ed;
  --victoria: #5e3e8c;
  --victoria-25: rgba(94, 62, 140, 0.25);
  --victoria-75: rgba(94, 62, 140, 0.75);

  /* Not part of original design palette */
  --color-guardsman-red: #c00;
  --color-salem: #048652;

  --color-score-1-bg: #093;
  --color-score-1-fg: black;
  --color-score-2-bg: #2ac85e;
  --color-score-2-fg: black;
  --color-score-3-bg: #95d3aa;
  --color-score-3-fg: black;
  --color-score-4-bg: #f4a806;
  --color-score-4-fg: black;
  --color-score-5-bg: #c00;
  --color-score-5-fg: white;

  --color-map-water: #d5e6f0;

  /* Lex color */
  --color-alto: #d3d3d3;
  --color-bizarre: #ede4da;
  --color-blue-ribbon: #0069eb;
  --color-gallery: #f0f0f0;
  --color-midnight-blue: #002e6d;
  --color-nobel: #b5b5b5;
  --color-pampas: #fbfaf9;
  --color-periwinkle: #c1d4ff;

  /* Not part of original palette, introduced for WCAG contrast reasons. About
   * the lightest gray that gives 3:0 contrast vs search field background
   */
  --color-dusty-gray: #949494; /* 3:0 vs white */

  /* From Lille design
   */
  --color-apple-green: #e0f2e9;
  --color-eucalyptus: #247b59;
  --color-gray-nurse: #f8f9f7;
  --color-plantation: #254337;
  --color-plantation-50: rgba(37, 67, 55, 0.5);
  --color-picton-blue: #4cd7ed;
  --color-scandal: #ddf6fb;
  --color-sea-green: #2d7b5c;
  --color-sea-green-75: rgba(45, 123, 92, 0.75);
  --color-silver-tree: #65c091;
}
