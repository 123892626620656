.reading-tip {
  position: relative;
  overflow: auto; /* In case of long words; SNL don't want to hyphenate/wrap */
}

.reading-tip__figure {
  margin: 0 0 var(--lineheight-tagline);
}

.theme-lille .reading-tip__figure {
  margin-bottom: 0.5rem;
}

/* This + object-fit is for the case when multiple of these tips are arranged
 * in a row: keep the headers aligned with each other.
 */
.reading-tip--theme .reading-tip__figure {
  height: 88px;
}

.reading-tip--front .reading-tip__figure {
  height: auto;
  aspect-ratio: 1544 / 586; /* Aspect ratio of topimage */
}

.reading-tip__image {
  max-width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: bottom left;
  vertical-align: top;
}

.reading-tip__tagline {
  font: var(--tagline);
  text-transform: uppercase;
  margin-bottom: 0;
}

.reading-tip__tagline--secondary {
  display: inline;
}

.reading-tip__header {
  margin-bottom: var(--lineheight-tagline);
}

.theme-lille .reading-tip__header {
  margin-bottom: 0.25rem;
}

.reading-tip--article .reading-tip__header {
  margin-bottom: var(--lineheight-meta);
}

.reading-tip__title {
  display: inline;
  font: var(--subtitle);
  font-variant-numeric: var(--numeric);
}

.reading-tip--article .reading-tip__title {
  font: var(--title);
  font-variant-numeric: var(--numeric);
}

/* Workaround for stray reading-tip__title-link classes in article text */
.reading-tip .reading-tip__title-link::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.reading-tip__preamble {
  max-width: 30em;
  margin-bottom: 0;
}

@media (--large-up) {
  .reading-tip--theme .reading-tip__figure {
    height: 96px;
  }
}

@media (--xlarge-up) {
  .reading-tip--theme .reading-tip__figure {
    height: 128px;
  }
}
