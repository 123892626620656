.activity {
  font: var(--small-body);
}

.activity__heading {
  font: var(--subtitle);
  font-variant-numeric: var(--numeric);
}

.activity__all {
  font: var(--tagline);
  text-transform: uppercase;
  border-bottom-style: none;
}

.activity__all:hover {
  border-bottom-style: solid;
}

@media (--small) {
  .activity {
    margin-bottom: var(--spacing1);
  }
}
