.site-header {
  display: grid;
  grid-template: "logo login" auto
                 "search search" auto
                / 1fr max-content;
  gap: var(--spacing1) var(--gutter);
  align-items: center;
}

.theme-lille .site-header {
  background: var(--color-silver-tree);
  grid-template-columns: max-content 1fr;
}

.site-header__frontpage-link {
  grid-area: logo;
  color: inherit;
}

.site-header__frontpage-link:link,
.site-header__frontpage-link:visited {
  border-bottom-style: none;
}

.site-header__login-home {
  grid-area: login;
  display: flex;
  flex-direction: column;
  gap: var(--half-gutter);
  justify-content: center;
  align-items: flex-end;
}

.site-header__home-link {
  font: var(--home-link-font);
  text-decoration: underline;
  border-bottom-style: none;
  text-align: right;
}

.site-header__search-form {
  grid-area: search;
  width: 100%;
}

@media (width < 375px) {
  .theme-lille .site-header {
    grid-template: "logo" auto
                   "login" auto
                   "search" auto
                  / 1fr;
  }
}

@media (--large-up) {
  .site-header,
  .theme-lille .site-header {
    padding: 0 var(--gutter);
    position: relative;
    grid-template: none;
    grid-template-columns: repeat(12, 1fr);
  }

  .theme-lille .site-header:not(.site-header--front) {
    grid-template-columns: max-content 1fr max-content;
  }

  .theme-lille .site-header--front {
    margin-top: var(--spacing2);
    row-gap: var(--spacing2);
  }

  .theme-lex .site-header {
    grid-template: "logo search login"
                  / 50% 1fr max-content;
    gap: 0;
  }

  .site-header__frontpage-link {
    grid-area: auto;
    grid-column: 1 / span 2;
  }

  .theme-lex .site-header__frontpage-link {
    grid-area: logo;
    max-width: 300px;
  }

  .site-header__login-home {
    order: 1;
    grid-area: auto;
    grid-column: 11 / span 2;
  }

  .theme-lex .site-header__login-home {
    grid-area: login;
  }

  .site-header__login {
    position: absolute;
    top: 0;
    right: var(--gutter);
  }

  .theme-lex .site-header__login-home:has(.site-header__login[hidden]) {
    display: none;
  }

  .theme-lille .site-header__login,
  .theme-lex .site-header__login {
    position: static;
  }

  .theme-lex .site-header__login {
    margin-left: 40px;
  }

  .site-header__search-form {
    grid-area: auto;
    grid-column: 3 / span 8;
  }

  .theme-lex .site-header__search-form {
    grid-area: search;
    padding-left: var(--half-gutter);
  }

  .theme-lille .site-header:not(.site-header--front) .site-header__frontpage-link,
  .theme-lille .site-header:not(.site-header--front) .site-header__login-home,
  .theme-lille .site-header:not(.site-header--front) .site-header__search-form {
    grid-column: auto;
  }

  .theme-lille .site-header--front .site-header__frontpage-link {
    grid-column: 3 / span 8;
  }
}
