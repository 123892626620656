.featured-article {
  margin-bottom: var(--spacing1);
  position: relative;
}

.featured-article__header {
  margin-bottom: var(--lineheight-tagline);
}

.featured-article__figure {
  margin: 0;
  min-height: 70px; /* in case image fails to load */
}

.featured-article__image {
  width: 100%;
  vertical-align: top;
}

.featured-article__tagline {
  font: var(--tagline);
  text-transform: uppercase;
  margin-top: var(--lineheight-tagline);
  margin-bottom: 0;
}

.featured-article__title {
  font: var(--title);
  font-variant-numeric: var(--numeric);
}

.featured-article__title-link::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (--medium-up) {
  .featured-article {
    margin-bottom: var(--spacing2);
  }

  .featured-article__tagline {
    margin-top: 0;
  }
}

@media (--medium) {
  .featured-article:after {
    content: "";
    display: block;
    clear: left;
  }

  .featured-article__figure {
    float: left;
    width: calc(3 / 6 * 100%);
    padding-right: var(--half-gutter);
  }

  .featured-article__tagline,
  .featured-article__title,
  .featured-article__preamble {
    margin-left: calc(3 / 6 * 100%);
    padding-left: var(--half-gutter);
  }
}

@media (--medium-plus-up) {
  .featured-article__header {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .featured-article__figure {
    margin-left: calc(-1 * var(--gutter));
  }

  .featured-article__tagline {
    order: -1;
    margin-bottom: var(--lineheight-tagline);
  }

  .featured-article__figure ~ .featured-article__title {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: var(--half-gutter);
    margin-left: calc(-1 * var(--half-gutter));
    background: rgba(255, 255, 255, 0.6);
  }

  .featured-article--light-text .featured-article__figure ~ .featured-article__title {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .featured-article--light-text .featured-article__figure ~ .featured-article__title .featured-article__title-link {
    color: white;
    border-bottom-color: white;
  }

  .featured-article__title-link::after {
    /* Unable to make the whole block clickable from here on out given the way
     * the title is currently positioned on top of the image, aligning to its
     * bottom edge (and with an arbitrary amount of "preamble" text below).
     * Would have to position it more like Lille page headers instead, growing
     * out past the bottom instead of upwards into the image.
     */
    display: none;
  }
}

@media (--large-up) {
  .featured-article__header {
    margin-left: calc(-1 * var(--half-gutter));
    margin-right: calc(-1 * var(--half-gutter));
  }

  .featured-article__figure {
    margin-left: calc(-1 / 6 * 100%);
    padding-left: var(--half-gutter);
    margin-right: calc(-1 * var(--half-gutter));
  }

  .featured-article__tagline,
  .featured-article__title {
    padding: 0 var(--half-gutter);
  }

  .featured-article__figure ~ .featured-article__title {
    margin-left: 0;
  }
}
