/* Copied from old design and reformatted/cleaned up. Origin uncertain. */

/* Insert */
.wikEdDiffInsert {
  font-weight: bold;
  background-color: #a1d99b;
  color: #222;
  border-radius: 0.25em;
  padding: 0.2em 1px;
}

/* Delete */
.wikEdDiffDelete {
  font-weight: bold;
  background-color: #fcbbaa;
  color: #a63603;
  text-decoration: line-through;
  border-radius: 0.25em;
  padding: 0.2em 1px;
}

/* Block */
.wikEdDiffBlock {
  font-weight: bold;
  background-color: #fed976;
  border-radius: 0.25em;
  padding: 0.2em 1px;
  margin: 0 1px;
}

.wikEdDiffBlock0 {
  background-color: #ffff80;
}

.wikEdDiffBlock1 {
  background-color: #d0ff80;
}

.wikEdDiffBlock2 {
  background-color: #ffd8f0;
}

.wikEdDiffBlock3 {
  background-color: #c0ffff;
}

.wikEdDiffBlock4 {
  background-color: #fff888;
}

.wikEdDiffBlock5 {
  background-color: #bbccff;
}

.wikEdDiffBlock6 {
  background-color: #e8c8ff;
}

.wikEdDiffBlock7 {
  background-color: #ffbbbb;
}

.wikEdDiffBlock8 {
  background-color: #a0e8a0;
}

.wikEdDiffBlockHighlight {
  background-color: #777;
  color: #fff;
  border: solid #777;
  border-width: 1px 0;
}

/* Mark */
.wikEdDiffMarkLeft,
.wikEdDiffMarkRight {
  font-weight: bold;
  background-color: #fed976;
  color: #666;
  border-radius: 0.25em;
  padding: 0.2em;
  margin: 0 1px;
}

.wikEdDiffMarkLeft:before {
  content: "◀";
}

.wikEdDiffMarkRight:before {
  content: "▶";
}

.wikEdDiffMarkLeft.wikEdDiffNoUnicode:before {
  content: "<";
}

.wikEdDiffMarkRight.wikEdDiffNoUnicode:before {
  content: ">";
}

.wikEdDiffMark {
  background-color: #e8e8e8;
  color: #666;
}

.wikEdDiffMark0 {
  background-color: #ffff60;
}

.wikEdDiffMark1 {
  background-color: #c8f880;
}

.wikEdDiffMark2 {
  background-color: #ffd0f0;
}

.wikEdDiffMark3 {
  background-color: #a0ffff;
}

.wikEdDiffMark4 {
  background-color: #fff860;
}

.wikEdDiffMark5 {
  background-color: #b0c0ff;
}

.wikEdDiffMark6 {
  background-color: #e0c0ff;
}

.wikEdDiffMark7 {
  background-color: #ffa8a8;
}

.wikEdDiffMark8 {
  background-color: #98e898;
}

.wikEdDiffMarkHighlight {
  background-color: #777;
  color: #fff;
}

/* Wrappers */
.wikEdDiffFragment {
  white-space: pre-wrap;
  background: #fff;
  background: none;
  border: #bbb solid;
  border: none;
  border-width: 1px 1px 1px 0.5em;
  border-radius: 0.5em;
  font-family: sans-serif;
  font-size: 88%;
  line-height: 1.6;
  box-shadow: 2px 2px 2px #ddd;
  box-shadow: none;
  padding: 1em;
  margin: 0;
}

.wikEdDiffNoChange {
  background: #f0f0f0;
  border: 1px #bbb solid;
  border-radius: 0.5em;
  line-height: 1.6;
  box-shadow: 2px 2px 2px #ddd;
  padding: 0.5em;
  margin: 1em 0;
  text-align: center;
}

.wikEdDiffSeparator {
  margin-bottom: 1em;
}

/* Newline */
.wikEdDiffNewline:before {
  content: "¶";
  color: transparent;
}

.wikEdDiffBlock:hover .wikEdDiffNewline:before {
  color: #aaa;
}

.wikEdDiffBlockHighlight .wikEdDiffNewline:before {
  color: transparent;
}

.wikEdDiffBlockHighlight:hover .wikEdDiffNewline:before {
  color: #ccc;
}

.wikEdDiffBlockHighlight:hover .wikEdDiffInsert .wikEdDiffNewline:before,
.wikEdDiffInsert:hover .wikEdDiffNewline:before {
  color: #999;
}

.wikEdDiffBlockHighlight:hover .wikEdDiffDelete .wikEdDiffNewline:before,
.wikEdDiffDelete:hover .wikEdDiffNewline:before {
  color: #aaa;
}

/* Tab */
.wikEdDiffTab {
  position: relative;
}

.wikEdDiffTabSymbol {
  position: absolute;
  top: -0.2em;
}

.wikEdDiffTabSymbol:before {
  content: "→";
  font-size: smaller;
  color: #ccc;
}

.wikEdDiffBlock .wikEdDiffTabSymbol:before {
  color: #aaa;
}

.wikEdDiffBlockHighlight .wikEdDiffTabSymbol:before {
  color: #aaa;
}

.wikEdDiffInsert .wikEdDiffTabSymbol:before {
  color: #aaa;
}

.wikEdDiffDelete .wikEdDiffTabSymbol:before {
  color: #bbb;
}

/* Space */
.wikEdDiffSpace {
  position: relative;
}

.wikEdDiffSpaceSymbol {
  position: absolute;
  top: -0.2em;
  left: -0.05em;
}

.wikEdDiffSpaceSymbol:before {
  content: "·";
  color: transparent;
}

.wikEdDiffBlock:hover .wikEdDiffSpaceSymbol:before {
  color: #999;
}

.wikEdDiffBlockHighlight .wikEdDiffSpaceSymbol:before {
  color: transparent;
}

.wikEdDiffBlockHighlight:hover .wikEdDiffSpaceSymbol:before {
  color: #ddd;
}

.wikEdDiffBlockHighlight:hover .wikEdDiffInsert .wikEdDiffSpaceSymbol:before,
.wikEdDiffInsert:hover .wikEdDiffSpaceSymbol:before {
  color: #888;
}

.wikEdDiffBlockHighlight:hover .wikEdDiffDelete .wikEdDiffSpaceSymbol:before,
.wikEdDiffDelete:hover .wikEdDiffSpaceSymbol:before {
  color: #999;
}

/* Error */
.wikEdDiffError .wikEdDiffFragment,
.wikEdDiffError .wikEdDiffNoChange {
  background: #faa;
}
