.quality-assessment-form {
  font: var(--meta);
}

.quality-assessment-form__list {
  list-style: none;
  padding: 0;
}

.quality-assessment-form__list-item {
  padding-left: 1.5em;
}

.quality-assessment-form__score {
  display: inline-block;
  min-width: 1em;
  text-align: center;
  margin-left: -1.5em;
  margin-right: 0.5em;
}

.quality-assessment-form__score--1 {
  color: var(--color-score-1-fg);
  background: var(--color-score-1-bg);
}

.quality-assessment-form__score--2 {
  color: var(--color-score-2-fg);
  background: var(--color-score-2-bg);
}

.quality-assessment-form__score--3 {
  color: var(--color-score-3-fg);
  background: var(--color-score-3-bg);
}

.quality-assessment-form__score--4 {
  color: var(--color-score-4-fg);
  background: var(--color-score-4-bg);
}

.quality-assessment-form__score--5 {
  color: var(--color-score-5-fg);
  background: var(--color-score-5-bg);
}

.quality-assessment-form__input {
  margin: 0;
}

.quality-assessment-form__textarea {
  width: 100%;
  height: 5rem;
}

.quality-assessment-form__earlier {
  font: var(--tagline);
  text-transform: uppercase;
}
