.skip-link {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  color: var(--alternate-link-color);
  font: var(--meta);
  padding: 10px;
  border: none;
}

.skip-link:not(:focus) {
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
}
