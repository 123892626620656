.radio-group {
  border: none;
  padding: 0;
  margin: 0 0 var(--lineheight-body);
}

.radio-group__legend {
  font: var(--category);
}

.radio-group__label {
  display: block;
  font: var(--meta);
}

.radio-group__radio {
  margin: 0;
}

@media (--medium-up) {
  .radio-group__legend,
  .radio-group__items {
    padding: 0 var(--half-gutter);
    width: calc(4 / 6 * 100%);
  }
}

@media (--medium-plus-up) {
  .radio-group {
    display: flex;
    align-items: center;
  }

  .radio-group__legend {
    width: calc(2 / 10 * 100%);
  }

  .radio-group__items {
    width: calc(4 / 10 * 100%);
  }
}

@media (--large-up) {
  .radio-group__legend {
    width: calc(2 / 12 * 100%);
    text-align: right;
  }

  .radio-group__items {
    width: calc(4 / 12 * 100%);
  }
}
