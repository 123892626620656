.sponsor-institution {
  font: var(--meta);
}

.sponsor-institution:after {
  content: "";
  display: block;
  clear: left;
}

.sponsor-institution__figure {
  margin: 0;
  float: left;
  width: 78px;
  margin-right: var(--half-gutter);
}

.sponsor-institution__image {
  max-width: 100%;
}
