.activity-list {
  font: var(--small-body);
  padding: 0;
  margin: 0;
}

.activity-list__item {
  list-style: none;
  margin: var(--lineheight-small-body) 0;
}

.activity-list__item-link {
  font-weight: bold;
  border-bottom-style: none;
}

.activity-list__item-link:hover,
.activity-list__item-link:focus {
  border-bottom-style: solid;
}

.activity-list__item-link:link {
  color: var(--alternate-link-color);
  border-bottom-color: var(--alternate-link-underline);
}

.activity-list--in-footer .activity-list__item-link:link {
  color: var(--link-color);
  border-bottom-color: var(--link-underline-focus);
}

.activity-list__time {
  font: var(--timestamp);
  text-transform: uppercase;
}
