.l-form-page {
  margin-top: var(--spacing4);
  margin-bottom: var(--spacing4);
}

.l-form-page__notice,
.l-form-page__title {
  margin-bottom: var(--spacing1);
}

.l-form-page__section {
  margin-top: var(--spacing2);
}

@media (--medium-up) {
  .l-form-page__intro,
  .l-form-page__block,
  .l-form-page__section {
    padding: 0 var(--half-gutter);
    width: calc(4 / 6 * 100%);
  }
}

@media (--medium-plus-up) {
  .l-form-page__intro,
  .l-form-page__block,
  .l-form-page__section {
    width: calc(10 / 10 * 100%);
  }
}

@media (--large-up) {
  .l-form-page__intro,
  .l-form-page__block,
  .l-form-page__section {
    width: calc(8 / 12 * 100%);
    position: relative;
    left: calc(2 / 12 * 100%);
  }
}
