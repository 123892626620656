.alphabet-nav__heading {
  font: var(--tagline);
  text-transform: uppercase;
}

.alphabet-nav__items {
  padding: 0;
}

.alphabet-nav__item {
  display: inline-block;
  font: var(--prioritized);
  padding: 0 0.25em 0 0;
  color: var(--silver);
}

.alphabet-nav--standalone .alphabet-nav__item {
  font: var(--article-subheading);
  font-weight: normal;
}

.alphabet-nav__link {
  border-bottom-style: none;
}

.alphabet-nav__link:hover,
.alphabet-nav__link:focus {
  border-bottom-style: solid;
}

.alphabet-nav__link:link {
  color: var(--alternate-link-color);
  border-bottom-color: var(--alternate-link-underline);
}
