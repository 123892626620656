.l-wrapper {
  padding: 0 var(--half-gutter);
}

@media (--medium-up) {
  .l-wrapper {
    width: var(--grid-width);
    margin: 0 auto;
  }

  .l-wrapper--no-gutter {
    padding-inline: var(--gutter);
  }
}
