.about-site {
  font: var(--small-body);
}

.about-site__heading {
  font: var(--subtitle);
}

.about-site__heading,
.about-site__description p {
  margin-bottom: var(--lineheight-small-body);
}

.about-site__list {
  padding: 0;
}

.about-site__item {
  list-style: none;
}

.about-site__home {
  border-bottom-style: none;
}

.about-site__link {
  font: var(--category);
  text-transform: uppercase;
}

.about-site__logo {
  width: auto;
  height: 40px;
}

@media (--medium-up) {
  .about-site__logo {
    height: 50px;
  }
}
