.article-contributions__heading {
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
}

.article-contributions__items {
  padding: 0;
  margin: 0;
}

.article-contributions__item {
  list-style: none;
}
