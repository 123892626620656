.featured-cards__header {
  margin-bottom: 0.75rem;
}

.featured-cards__cards {
  display: grid;
  grid-template-columns: 100%;
  gap: var(--gutter);
}

.featured-cards__other {
  display: grid;
  grid-template-columns: 100%;
  gap: var(--gutter);
}

@media (--small) {
  .featured-cards {
    /* Don't want images to get bigger than the biggest possible for medium-up
     * breakpoints. It would require larger image files and the images would
     * take up as much visual space as the main topimage, or even more, in the
     * case of square images
     */
    max-width: 496px;
  }
}

@media (--medium-up) {
  .featured-cards__header,
  .featured-cards__cards {
    padding-inline: var(--half-gutter);
  }

  .featured-cards__other {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (--large-up) {
  .featured-cards__header {
    margin-bottom: 30px;
  }

  .featured-cards__cards {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .featured-cards__main {
    grid-column: 1 / span 9;
  }

  .featured-cards__other {
    grid-column: 10 / span 3;
    grid-template-columns: 100%;
    gap: 40px;
  }
}
