.site-logo {
  display: block;
  font: var(--logo-font);
  text-transform: uppercase;
}

.site-logo--lille {
  text-transform: none;
}

.site-logo--snl,
.site-logo--lille {
  display: flex;
  align-items: center;
}

.site-logo--light-text {
  color: white;
}

.site-logo__small-image {
  vertical-align: top;
  display: inline;
}

.site-logo__image {
  max-width: 100%;
  height: 40px;
  vertical-align: top;
}

.site-logo__small-image + .site-logo__image {
  display: none;
}

.site-logo--snl .site-logo__image,
.site-logo--lille .site-logo__image {
  width: 40px;
  margin-right: var(--half-gutter);
}

.site-logo--lex .site-logo__image {
  height: 48px;
}

@media (--medium-up) {
  .site-logo__image {
    height: 50px;
  }

  .site-logo--snl .site-logo__image,
  .site-logo--lille .site-logo__image {
    width: 50px;
    height: 50px;
  }
}

@media (--large-up) {
  .site-logo {
    display: block;
  }

  .site-logo--lille {
    display: flex;
  }

  .site-logo--lex {
    display: inline-block;
  }

  .theme-lille .site-logo--front {
    justify-content: center;
    gap: var(--half-gutter);
  }

  .site-logo__small-image {
    display: none;
  }

  .site-logo__image {
    width: 100%; /* overridden by snl/lille variants above */
    height: auto; /* overridden by snl/lille variants above */
  }

  .site-logo__small-image + .site-logo__image {
    display: inline;
  }

  .site-logo--snl .site-logo__image {
    display: block;
    margin-right: 0;
    margin-bottom: var(--half-gutter);
    margin-left: -8px; /* Try to left-align the tail of the bird */
  }

  .theme-lille .site-logo--front .site-logo__image {
    width: 90px;
    height: 90px;
  }

  .site-logo--lex .site-logo__image {
    height: 76px;
  }

  .site-logo__text {
    white-space: pre-line;
  }

  .theme-lille .site-logo--front .site-logo__text {
    font-size: 1.5em;
    line-height: 1.27;
  }
}
