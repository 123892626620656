.authoritative-editor {
  position: relative;
  padding-left: calc(78px + var(--half-gutter));
  min-height: 78px;
}

.authoritative-editor--missing {
  padding-left: 0;
  min-height: 0;
}

.authoritative-editor__heading,
.authoritative-editor__suffix {
  font: var(--tagline);
  text-transform: uppercase;
}

.authoritative-editor__secondary-link {
  border-bottom-style: none;
}

.authoritative-editor__secondary-link:hover,
.authoritative-editor__secondary-link:focus {
  border-bottom-style: solid;
}

.authoritative-editor__image {
  position: absolute;
  top: 0;
  left: 0;
}

.authoritative-editor__name {
  font: var(--subtitle);
  font-variant-numeric: var(--numeric);
  display: inline-block;
}
