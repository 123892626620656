@font-face {
  font-family: "Publico text";
  src: url("../fonts/PublicoText-Roman-Web.woff2") format("woff2"),
       url("../fonts/PublicoText-Roman-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Publico text";
  src: url("../fonts/PublicoText-Italic-Web.woff2") format("woff2"),
       url("../fonts/PublicoText-Italic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Publico text";
  src: url("../fonts/PublicoText-Semibold-Web.woff2") format("woff2"),
       url("../fonts/PublicoText-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Publico text";
  src: url("../fonts/PublicoText-Bold-Web.woff2") format("woff2"),
       url("../fonts/PublicoText-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Publico text";
  src: url("../fonts/PublicoText-BoldItalic-Web.woff2") format("woff2"),
       url("../fonts/PublicoText-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Work Sans";
  src: url("../fonts/WorkSans-Regular.woff2") format("woff2"),
       url("../fonts/WorkSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Work Sans";
  src: url("../fonts/WorkSans-Italic.woff2") format("woff2"),
       url("../fonts/WorkSans-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Work Sans";
  src: url("../fonts/WorkSans-SemiBold.woff2") format("woff2"),
       url("../fonts/WorkSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Work Sans";
  src: url("../fonts/WorkSans-Bold.woff2") format("woff2"),
       url("../fonts/WorkSans-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("../fonts/GothamMedium-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Material Icons";
  src: url("../fonts/material-icons.woff2") format("woff2"),
       url("../fonts/material-icons.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: block;
}

@font-face {
  font-family: "Guardian Sans";
  src: url("../fonts/GuardianSansRegular.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Guardian Sans";
  src: url("../fonts/GuardianSansRegularIt.otf") format("opentype");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "Guardian Sans";
  src: url("../fonts/GuardianSansSemibold.otf") format("opentype");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Variable.woff2") format("woff2-variations"),
       url("../fonts/Inter-Variable.woff") format("woff-variations");
  font-style: normal;
  font-weight: 100 900;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-RegularItalic.woff2") format("woff2"),
       url("../fonts/Inter-RegularItalic.woff") format("woff");
  font-style: italic;
  font-weight: 400;
}
