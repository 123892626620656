.l-article-index {
  margin-top: var(--spacing1);
  margin-bottom: var(--spacing4);
}

.l-article-index__main::before {
  content: "";
  display: block;
  border-top: 1px solid;
  margin: var(--spacing2) 0;
}

.l-article-index__pagination-note {
  margin: 1rem 0;
}

.l-article-index__articles {
  margin-top: var(--spacing1);
}

@media (--medium-up) {
  .l-article-index__nav,
  .l-article-index__contents {
    padding: 0 var(--half-gutter);
  }

  .l-article-index__main::before {
    margin-inline: var(--half-gutter);
  }
}

@media (--large-up) {
  .l-article-index__nav,
  .l-article-index__contents {
    width: calc(10 / 12 * 100%);
    margin-left: auto;
  }
}
