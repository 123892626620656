.credit__title,
.credit__suffix {
  font: var(--tagline);
  text-transform: uppercase;
}

.credit__name,
.credit__suffix {
  margin-bottom: 0;
}

.credit__emphasis {
  font: var(--prioritized);
}
