.alternative-article-version {
  --icon-size: 20px;
  background: var(--color-scandal);
  border-left: 3px solid var(--color-picton-blue);
  padding: 11px;
  font: var(--meta);
  margin-bottom: var(--spacing1);
  text-wrap: balance;
}

/* This is not using the usual theme scopes because we care about the CSS theme
 * of the _target_ encyclopedia, not the encyclopedia we're in currently.
 */
.alternative-article-version--lille {
  background: var(--color-apple-green);
  border-left-color: var(--color-silver-tree);
}

.alternative-article-version__link {
  font-weight: 600;
  padding-block: 3px; /* hopefully enough to fit the icon (content-height is undefined in CSS2) */
  padding-right: calc(var(--icon-size) + 5px);
  background: url(../images/crosslink.svg) center right no-repeat;
  background-size: var(--icon-size);
  border-bottom: 0;
  text-decoration: underline;
}

.alternative-article-version--lille .alternative-article-version__link {
  background-image: url(../images/crosslink_lille.svg);
}

@media (--small) {
  .alternative-article-version {
    font: var(--meta-small);
  }
}

@media (--large-up) {
  .alternative-article-version {
    padding: 18px;
  }
}
