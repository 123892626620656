.info-box {
  border: 1px solid;
  padding: var(--lineheight-tagline) var(--half-gutter);
  font: var(--meta);
  background: var(--pale-canary);
}

.info-box__strong {
  font: var(--category);
}

/* Same as article-text component: should be a BEM class, but we don't
 * currently have a clean way to transform user-originated rich text.
 * Paragraphs may occur in generated content (type info_box).
 */
.info-box p {
  margin: 0.25em 0 0;
}
