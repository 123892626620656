/*
 "Lookup definition" is a proof-of-concept feature.
 It is only intended for Lex and not other tenants.
*/

.lookup-definitions-background {
  position: fixed;
  display: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.lookup-definitions {
  display: none;
  position: fixed;

  width: 90vw;
  max-width: 500px;
  max-height: 50vh;
  top: 25%;
  left: calc(50% - 250px);

  overflow: auto; /* In case of long words; SNL don't want to hyphenate/wrap */
  padding: 1rem;

  font-size: 14px;
  line-height: 1.5;
  font-family: Verdana, Lucida, Helvetica, Arial, sans-serif !important;
  color: #333333;
  background-color: white;
}

.lookup-definitions__ddo-logo {
  width: 90%;
}

.lookup-definitions__close--small {
  background: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  position: absolute; /* IE11 fallback */
  position: sticky;
  top: 0;
  vertical-align: top;
}

.lookup-definitions__word {
  font-size: 25px;
  color: #CC0000;
}

.lookup-definitions__conjugation {
  font-size: 13px;
  color: #363636;
}

.lookup-definitions__label-box {
  text-transform: uppercase;
  background-color: #EAEFF2;
  border: 1px solid #CCC;
  letter-spacing: 0.1em;
  padding: 0 0.3em;
  margin: 0 0.6em 0 0;
  font-size: 10px;
}

.lookup-definitions__partOfSpeech {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 13px;
  color: #363636;
}

.lookup-definitions__definitions__domain {
  text-transform: uppercase;
  padding-right: 0.2em;
  color: #666;
}

.lookup-definitions__definitions,
.lookup-definitions__definitions__subdefinitions {
  padding-left: 1rem;
}

.lookup-definitions__definitions--single-item {
  list-style-type: none;
  padding-left: 0;
}

.lookup-definitions__label-box--synonyms + span a:not(:last-child):after,
.lookup-definitions__label-box--relateds + span a:not(:last-child):after {
  content: ', ';
}

.lookup-definitions__definitions__subdefinitions {
  list-style-type: lower-latin;
  margin: 0;
}

.lookup-definitions__hits {
  margin-top: 1.1428em;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(94, 62, 140, 0.25);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

.lookup-definitions__hits, .lookup-definitions__hits::after {
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  color: #363636;
}

.lookup-definitions__close--full {
  position: static;
  background-color: #E6E6E6;
  cursor: pointer;
  border-radius: 0.25rem;
  border: none;
  padding: 0.5rem 1rem;
}

html[lang="nb"] .lookup-definitions__hits:after { content: ' ord funnet:'; }
html[lang="da"] .lookup-definitions__hits:after { content: ' ord fundet:'; }
html[lang="en"] .lookup-definitions__hits:after { content: ' words found:'; }

html[lang="nb"] .lookup-definitions__label-box--synonyms::before { content: 'Synonym'; }
html[lang="da"] .lookup-definitions__label-box--synonyms::before { content: 'Synonym'; }
html[lang="en"] .lookup-definitions__label-box--synonyms::before { content: 'Synonym'; }

html[lang="nb"] .lookup-definitions__label-box--relateds::before { content: 'Se også'; }
html[lang="da"] .lookup-definitions__label-box--relateds::before { content: 'Se også'; }
html[lang="en"] .lookup-definitions__label-box--relateds::before { content: 'See also'; }

html[lang="nb"] .lookup-definitions__label-box--conjugation::before { content: 'Bøyning'; }
html[lang="da"] .lookup-definitions__label-box--conjugation::before { content: 'Bøjning'; }
html[lang="en"] .lookup-definitions__label-box--conjugation::before { content: 'Conjugation'; }

html[lang="nb"] .lookup-definitions__label-box--usage::before { content: 'Bruk'; }
html[lang="da"] .lookup-definitions__label-box--usage::before { content: 'Sprogbrug'; }
html[lang="en"] .lookup-definitions__label-box--usage::before { content: 'Usage'; }

html[lang="nb"] .lookup-definitions__close--full::before { content: 'Lukk'; }
html[lang="da"] .lookup-definitions__close--full::before { content: 'Luk'; }
html[lang="en"] .lookup-definitions__close--full::before { content: 'Close'; }

.lookup-definitions__separator {
  border: 1px solid #F5F5F5;
}
