.create-comment {
  margin: var(--spacing1) 0;
}

.create-comment__intro {
  font: var(--meta);
}

.create-comment__form {
  background: white;
  padding: var(--half-gutter);
  padding-bottom: var(--gutter);
}

.create-comment__label {
  font: var(--tagline);
  text-transform: uppercase;
}

.create-comment__textarea {
  width: 100%;
  margin: var(--lineheight-tagline) 0;
  font: inherit;
  resize: vertical;
}

@media (--medium-up) {
  .create-comment__form {
    margin: 0 calc(-1 * var(--gutter));
    padding-left: var(--gutter);
    padding-right: var(--gutter);
  }

  .create-comment__textarea {
    padding: var(--half-gutter);
  }
}
