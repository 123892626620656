@media print {
  * {
    color: black !important;
    background: white !important;
  }

  .user-menu,
  .l-header,
  .l-article__menu,
  .l-article__contributions,
  .l-footer {
    display: none !important;
  }

  .factbox {
    border: 1px solid;
  }
}
