.l-theme {
  padding-top: 24px;
}

.l-theme__publishing-tools {
  display: flex;
  gap: 1rem;
  margin-bottom: var(--spacing1);
}

.l-theme__intro,
.l-theme__toc {
  margin-bottom: var(--spacing1);
}

.l-theme__section {
  padding-block: 24px;
}

.l-theme__section--white {
  background: white;
}

@media (--small) {
  .l-theme__list {
    /* Match widths of card-list and featured-cards, for consistency. These have
     * a max width to avoid stretching images too much.
     */
    max-width: 496px;
  }
}

@media (--medium-up) {
  .l-theme__notice,
  .l-theme__publishing-tools,
  .l-theme__toc {
    margin-inline: var(--half-gutter);
  }

  .l-theme__intro {
    padding: 0 var(--half-gutter);
    width: calc(4 / 6 * 100%);
  }
}

@media (--medium-plus-up) {
  .l-theme__intro {
    width: calc(6 / 10 * 100%);
  }
}

@media (--large-up) {
  .l-theme {
    padding-top: 50px;
  }

  .l-theme__intro {
    width: calc(8 / 12 * 100%);
  }

  .l-theme__section {
    padding-block: 50px;
  }
}
