.link-list__heading--large {
  font: var(--subtitle-large);
  font-variant-numeric: var(--numeric);  
}

.link-list__list {
  margin: 0;
  padding: 0;
}

.link-list__item {
  list-style: none;
  padding-top: var(--lineheight-category); /* Not margin, due to multicol */
  overflow: auto; /* In case of long words; SNL don't want to hyphenate/wrap */
}

.link-list__category {
  font: var(--category);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link-list__link {
  border-bottom-style: none;
}

.link-list--years .link-list__link {
  font: var(--prioritized);
}

.link-list__link:hover,
.link-list__link:focus {
  border-bottom-style: solid;
}

.link-list__link:link {
  color: var(--alternate-link-color);
  border-bottom-color: var(--alternate-link-underline);
}

.link-list__see-more {
  font: var(--tagline);
  text-transform: uppercase;
  display: inline-block; /* for the top margin */
  margin-top: var(--lineheight-tagline);
  border-bottom-style: none;
}

.link-list__see-more:hover,
.link-list__see-more:focus {
  border-bottom-style: solid;
}

@media (--medium-up) {
  .link-list--large .link-list__list,
  .link-list--xlarge .link-list__list {
    columns: 3;
    column-gap: var(--gutter);
  }

  .link-list--large .link-list__item,
  .link-list--xlarge .link-list__item {
    break-inside: avoid-column;
    page-break-inside: avoid; /* Firefox uses this for columns */
    display: inline-block; /* Makes Firefox avoid breaks when few (2) items */
    width: 100%;
  }
}

@media (--medium-plus-up) {
  .link-list--xlarge .link-list__list {
    columns: 5;
  }
}
