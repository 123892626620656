/* Used for regular articles plus other similar but simpler pages such as
 * internal server error page
 */
.l-article {
  margin-top: var(--spacing1);
  padding: 0 var(--half-gutter);
  min-height: 50vh;
}

.theme-lex .l-article {
  margin-top: var(--spacing3);
}

.l-article:after {
  content: "";
  display: block;
  clear: both;
}

/* This wrapper only exists to manage z-index on the ".l-" level, which seems
 * theoretically "cleaner" than having components place themselves without
 * knowledge of the full picture
 */
.l-article__skip-to-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  z-index: 1;
}

.l-article__toc {
  display: none;
}

.l-article__figure {
  transition: width 0.2s ease-in-out;
}

.l-article__info-box,
.l-article__info,
.l-article__factbox,
.l-article__series-nav,
.l-article__contributions,
.l-article__authoritative-editor,
.l-article__highlighted-consultant,
.l-article__sponsor-institution,
.l-article__advisor {
  margin-bottom: var(--spacing1);
}

.l-article__info,
.l-article__authoritative-editor,
.l-article__highlighted-consultant {
  overflow: auto; /* In case of long words; SNL don't want to hyphenate/wrap */
}

.l-article__subheading {
  margin-bottom: 5px;
  text-wrap: balance;
  /* In the rare case of very long words (see e.g.
   * https://wja.slack.com/archives/C02FY7L96/p1733404055799039
   */
  overflow-wrap: break-word;
}

.theme-lille .l-article__subheading {
  margin-bottom: 0;
}

h2.l-article__subheading {
  font: var(--article-subheading);
  font-variant-numeric: var(--numeric);
}

h3.l-article__subheading {
  font: var(--article-subsubheading);
  font-variant-numeric: var(--numeric);
  margin-top: var(--lineheight-body);
}

.l-article__comments {
  position: relative;
}

.l-article__comments:before {
  content: "";
  display: block;
  border-top: 1px solid;
  margin-bottom: var(--spacing1);
  width: 100%;
}

.l-article__comments:after {
  content: "";
  border-width: 1px;
  border-style: solid solid none none;
  background: var(--black-haze);
  transform: rotate(-45deg);
  transform-origin: top left;
  position: absolute;
  top: 0;
  left: var(--half-gutter);
  width: var(--gutter);
  height: var(--gutter);
}

.l-article__credits {
  border-top: 1px solid;
  padding-top: var(--spacing1);
}

.l-article__preview-evaluation {
  margin: var(--spacing2) 0;
}

@media (--small) {
  .l-article__section {
    display: flex;
    flex-direction: column;
  }

  h3.l-article__subheading {
    margin-top: 0;
  }

  .l-article__figure {
    order: 1;
    margin: 0 calc(-1 * var(--half-gutter)) var(--spacing1);
  }

  .l-article__figure--picture + .l-article__figure--picture {
    margin-top: calc(var(--half-gutter) - var(--spacing1));
  }

  .l-article__gallery {
    margin: 0 calc(-1 * var(--half-gutter)) var(--spacing1);
  }
}

@media (--medium-up) {
  .l-article {
    padding: 0 var(--half-gutter);
    width: var(--grid-width);
    margin-inline: auto;
  }

  .l-article__menu,
  .l-article__subheading,
  .l-article__body-text,
  .l-article__tagsonomies,
  .l-article__series-nav,
  .l-article__info,
  .l-article__comments-contents {
    padding-left: var(--half-gutter);
    padding-right: var(--half-gutter);
    width: calc(4 / 6 * 100%);
  }

  .l-article__breadcrumb {
    padding-left: var(--half-gutter);
    padding-right: var(--half-gutter);
    width: calc(6 / 6 * 100%);
  }

  .l-article__info-box {
    width: calc(4 / 6 * 100%);
  }

  .l-article__factbox--large {
    padding: 0 var(--half-gutter);
    width: calc(6 / 6 * 100%);
    clear: right;
  }

  .l-article__figure,
  .l-article__factbox--small {
    margin-bottom: var(--spacing1);
  }

  .l-article__figure--normal,
  .l-article__factbox--small {
    padding: 0 var(--half-gutter);
    width: calc(2 / 6 * 100%);
    float: right;
    clear: right;
  }

  .l-article__figure--zoomed {
    width: calc(4 / 6 * 100%);
  }

  .l-article__figure--large {
    width: calc(4 / 4 * 100%);
    /* Hack: There's enough room for a floating figure beside this. But there
     * might not be enough room if the floating figure is zoomed. This
     * establishes a new BFC to avoid big white space at the top of the figure,
     * but thereby invokes undefined behavior. UAs _may_ make the large figure
     * narrower and jam it next to the float (CSS 2.1).
     */
    display: inline-block;
  }

  .l-article__figure--xlarge {
    width: calc(6 / 4 * 100%); /* non-calc fallback */
    width: calc(6 / 4 * 100% + var(--half-gutter));
    clear: right;
  }

  .l-article__gallery {
    padding: 0 var(--half-gutter);
    margin-bottom: var(--spacing1);
    clear: right;
  }

  .l-article__meta {
    display: flex;
    align-items: flex-end;
    clear: right;
  }

  .l-article__contributions {
    padding: 0 var(--half-gutter);
    width: calc(2 / 6 * 100%);
  }

  .l-article__comments:before {
    margin: 0 var(--half-gutter) var(--spacing1);
  }

  .l-article__comments:after {
    left: calc(4 / 6 * 100%);
    margin-left: var(--half-gutter);
  }

  .l-article__credits {
    margin: 0 var(--half-gutter);
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: var(--gutter);
  }

  .l-article__authoritative-editor,
  .l-article__highlighted-consultant {
    grid-column: 1 / span 4;
  }

  .l-article__sponsor-institution {
    grid-column: 5 / span 2;
  }

  .l-article__preview-evaluation {
    padding: 0 var(--half-gutter);
  }
}

@media (--medium-plus-up) {
  .l-article__menu,
  .l-article__subheading,
  .l-article__body-text,
  .l-article__tagsonomies,
  .l-article__series-nav,
  .l-article__info,
  .l-article__comments-contents {
    width: calc(6 / 10 * 100%);
  }

  .l-article__info-box {
    width: calc(6 / 10 * 100%);
  }

  .l-article__factbox--large {
    width: calc(10 / 10 * 100%);
  }

  .l-article__figure--normal,
  .l-article__factbox--small,
  .l-article__contributions {
    width: calc(4 / 10 * 100%);
  }

  .l-article__figure--zoomed {
    width: calc(6 / 10 * 100%);
  }

  .l-article__figure--large {
    width: 100%;
  }

  .l-article__figure--xlarge {
    width: calc(10 / 6 * 100%); /* non-calc fallback */
    width: calc(10 / 6 * 100% + var(--half-gutter) * 4 / 3);
  }

  .l-article__comments:after {
    left: calc(6 / 10 * 100%);
  }

  .l-article__credits {
    grid-template-columns: repeat(10, 1fr);
  }

  .l-article__authoritative-editor,
  .l-article__highlighted-consultant {
    grid-column: 1 / span 6;
  }

  .l-article__sponsor-institution {
    grid-column: 7 / span 4;
  }
}

@media (--large-up) {
  .l-article__toc {
    display: block;
  }

  .l-article__subheading,
  .l-article__body-text,
  .l-article__tagsonomies,
  .l-article__series-nav,
  .l-article__info,
  .l-article__comments-contents {
    width: calc(6 / 12 * 100%);
    margin-left: calc(2 / 12 * 100%);
  }

  .l-article__subheading {
    margin-bottom: 10px;
  }

  .l-article__info-box {
    width: calc(6 / 12 * 100%);
    margin-left: calc(2 / 12 * 100%);
  }

  .l-article__standalone-topimage,
  .l-article__factbox--large {
    width: calc(10 / 12 * 100%);
    margin-left: calc(2 / 12 * 100%);
  }

  .l-article__breadcrumb {
    width: calc(10 / 12 * 100%);
    margin-left: calc(2 / 12 * 100%);
  }

  .l-article__menu {
    width: calc(2 / 12 * 100%);
    float: left;
  }

  .l-article__factbox--small,
  .l-article__figure--normal {
    width: calc(4 / 12 * 100%);
  }

  .l-article__figure--zoomed {
    width: calc(6 / 12 * 100%);
  }

  .l-article__gallery {
    width: calc(10 / 12 * 100%);
    margin-left: calc(2 / 12 * 100%);
  }

  .l-article__meta {
    clear: both;
  }

  .l-article__contributions {
    width: calc(4 / 12 * 100%);
  }

  .l-article__comments:after {
    left: calc(8 / 12 * 100%);
  }

  .l-article__credits {
    grid-template-columns: repeat(12, 1fr);
  }

  .l-article__authoritative-editor,
  .l-article__highlighted-consultant {
    grid-column: 3 / span 6;
  }

  .l-article__sponsor-institution {
    grid-column: 9 / span 4;
  }

  .l-article__preview-evaluation {
    margin-left: calc(2 / 12 * 100%);
  }
}
