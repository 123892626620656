.l-search {
  margin-top: var(--spacing1);
  padding: 0 var(--half-gutter);
  margin-bottom: var(--spacing3);
}

.l-search__info,
.l-search__result {
  margin: var(--spacing2) 0;
}

@media (--medium-up) {
  .l-search {
    width: var(--grid-width);
    margin-inline: auto;
    display: flex;
  }

  .l-search__main-results {
    padding: 0 var(--half-gutter);
    width: calc(4 / 6 * 100%);
  }
}

@media (--medium-plus-up) {
  .l-search__main-results {
    width: calc(6 / 10 * 100%);
  }
}

@media (--large-up) {
  .l-search__main-results {
    width: calc(6 / 12 * 100%);
    margin-left: calc(2 / 12 * 100%);
  }
}
