.edit-links {
  position: relative;
  columns: 2;
}

.edit-links__heading {
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
}

.edit-links__list {
  padding: 0;
  margin: 0;
  list-style: none;
  font: var(--meta);
}

.edit-links__item {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
  break-inside: avoid;
}

.edit-links__link:not(:hover):not(:focus) {
  border-bottom-color: transparent;
}

.edit-links__icon {
  width: 15px;
  height: 15px;
  position: absolute;
  left: 0;
  top: 4px; /* vertically center in the 22px-height linebox: (22-15)/2 is about 4 */
}

@media (--medium-up) {
  .edit-links {
    columns: 3;
  }
}

@media (--medium-plus-up) {
  .edit-links {
    columns: 2;
  }

  .edit-links--standalone {
    columns: 3;
  }
}

@media (--large-up) {
  .edit-links {
    columns: 3;
  }

  .edit-links__icon {
    top: 5px; /* vertically center in the 24px-height linebox: (24-15)/2 is about 5 */
  }
}
