.profile-image {
  display: inline-block;
  width: var(--profile-image-size);
  height: var(--profile-image-size);
  margin: 0;
  border: 5px solid white;
}

.profile-image__image {
  vertical-align: top;
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
}

.profile-image__image:hover,
.profile-image__image:focus {
  filter: none;
}
