:root {
  --lineheight-category: 20px;
  --lineheight-tagline: 20px;

  --profile-image-size: 78px;
  --front-hero-mobile-min-height: 180px;
}

html {
  --grid-width: auto;
  --gutter: 24px;
  --half-gutter: 12px;
  --spacing1: 24px;
  --spacing2: 30px;
  --spacing3: 40px;
  --spacing4: 50px;
  --lineheight-body: 24px;
  --lineheight-meta: 22px;
  --lineheight-small-body: 22px;
}

@media (--medium-up) {
  html {
    --grid-width: calc(92px * 6 + 24px * 7);
    --spacing1: 30px;
    --spacing2: 40px;
    --spacing3: 50px;
    --spacing4: 60px;
  }
}

@media (--medium-plus-up) {
  html {
    --grid-width: calc(65px * 10 + 24px * 11);
  }
}

@media (--large-up) {
  html {
    --grid-width: calc(72px * 12 + 28px * 13);
    --gutter: 28px;
    --half-gutter: 14px;
    --spacing3: 60px;
    --spacing4: 80px;
    --lineheight-body: 27px;
    --lineheight-meta: 27px;
    --lineheight-small-body: 24px;
  }
}

@media (--xlarge-up) {
  html {
    --grid-width: calc(85px * 12 + 28px * 13);
    --spacing1: 35px;
    --spacing2: 50px;
    --spacing3: 75px;
    --spacing4: 100px;
    --lineheight-body: 30px;
    --lineheight-small-body: 26px;
  }
}

@media (--xlarge-plus-up) {
  html {
    --grid-width: calc(103px * 12 + 28px * 13);
  }
}
