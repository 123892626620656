.version-list {
  padding: 0;
  list-style: none;
}

.version-list__item {
  display: flex;
  background: white;
  border-bottom: none;
  margin: var(--half-gutter) 0;
  font: var(--meta);
}

.theme-lille .version-list__item {
  background: var(--black-haze);
}

.version-list__item:link {
  color: var(--alternate-link-color);
}

.version-list__version-info {
  margin: 0;
  padding: var(--half-gutter);
  border-right: 1px solid var(--silver);
  text-align: center;
}

.version-list__version-number {
  display: block;
  font: var(--subtitle-large);
  font-variant-numeric: var(--numeric);
  border-bottom: 1px solid transparent;
}

.version-list__item:link:hover .version-list__version-number {
  border-bottom-color: var(--alternate-link-underline);
}

.version-list__item:visited:hover .version-list__version-number {
  border-bottom-color: var(--victoria-75);
}

.version-list__description {
  margin: 0;
  padding: var(--half-gutter);
}

.version-list__payable {
  margin: 0;
  padding: 0 var(--half-gutter) var(--half-gutter);
  color: var(--color-salem);
}

.version-list__payable-info,
.version-list__payable-comment {
  margin: 0;
}

.version-list__timestamp {
  display: block;
  padding: 0 var(--half-gutter) var(--half-gutter);
  font: var(--timestamp);
  text-align: right;
}

.version-list__editors-only {
  font: var(--meta-bold);
  padding: var(--half-gutter);
}

.version-list__details {
  width: 100%;
} 

@media (--medium-up) {
  .version-list__info {
    display: flex;
    justify-content: space-between;
  }

  .version-list__timestamp {
    padding-top: var(--half-gutter);
  }
}
