.map {
  margin: 0;
  padding: var(--half-gutter);
  background: white;
  font: var(--meta);
}

.map__height-setter {
  position: relative;
  padding-top: calc(280 / 480 * 100%);
}

.map__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-map-water);
}

.map__caption {
  margin-top: 0.5rem;
}

.map__attribution {
  font: var(--meta-small);
  margin-top: var(--half-gutter);
}

/* Move the zoom buttons to the bottom right corner */
.map .ol-zoom {
  top: auto;
  left: auto;
  right: .5em;
  bottom: .5em;
}

/* Map type switcher button styles
.map-control {
    left: 8px;
    bottom: 8px;
}

.map-control > button {
    width: 2.75em !important;
    height: 2.75em !important;
}

#other-map {
    width: 100%;
    height: 100%;
}
*/

@media (--medium-up) {
  .map__height-setter {
    padding-top: 75%;
  }
}
