.card {
  position: relative;
  overflow: auto; /* In case of long words; SNL don't want to hyphenate/wrap */
}

.card--noimage {
  background: white;
  padding: 10px;
}

.card__figure {
  margin: 0 0 0.75rem;
  aspect-ratio: 1544 / 586; /* Aspect ratio of topimage */
}

.card__figure--square {
  aspect-ratio: 1 / 1;
}

.theme-lille .card__figure {
  margin-bottom: 0.25rem;
}

.card__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: bottom left;
  vertical-align: top;
}

.card__tagline {
  margin: 0;
  font: var(--category);
}

.card__title {
  margin-bottom: 0.5rem;
  text-wrap: balance;
}

.theme-lex .card__title {
  font: var(--front-large-link);
}

.theme-lille .card__title {
  font: var(--subtitle);
  margin-bottom: 0.25rem;
}

.card__link {
  border-bottom-style: none;
}

.card__link:hover,
.theme-lille .card__link {
  border-bottom-style: solid;
}

.card__link::after {
  content: "";
  position: absolute;
  inset: 0;
}

.card__preamble {
  font: var(--small-body);
  margin-bottom: 0;
}

.theme-lex .card__preamble {
  font: var(--front-preamble);
}

.theme-lille .card__preamble {
  font: var(--body);
}

@media (--medium-up) {
  .card--noimage {
    padding: 20px;
  }

  .card__figure {
    margin-bottom: 1rem;
  }

  .card--featured .card__figure {
    aspect-ratio: 989 / 450; /* From Lex sketches (taller than topimage) */
  }
}
