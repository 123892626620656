.timeline {
  margin: 0;
}

.timeline--box {
  padding: var(--half-gutter);
  background: white;
}

.timeline--autonumber {
  counter-reset: timeline;
}

.theme-lille .timeline--box {
  border: 2px solid var(--color-silver-tree);
}

.timeline__heading {
  margin-bottom: var(--lineheight-tagline);
}

.timeline__items {
  margin: 0;
  position: relative;
}

.timeline__items.is-truncated {
  overflow: hidden; /* If overflow-y, Firefox makes it focusable */
  height: 500px;
}

.timeline__items.is-truncated:after {
  content: "";
  background: linear-gradient(180deg, transparent, white 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  pointer-events: none;
}

.timeline__item {
  margin-left: var(--half-gutter);
  padding-left: var(--gutter);
  padding-bottom: 1px; /* Prevent margin collapsing i.e. gaps in left border */
  position: relative;
}

.timeline__item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-left: 1px solid;
}

.timeline__item:first-child:before {
  top: calc(0.5 * var(--half-gutter));
}

.timeline__item:last-child:before {
  height: calc(var(--half-gutter));
}

.timeline__item:after {
  content: "";
  position: absolute;
  top: calc(0.5 * (20px - var(--half-gutter)));
  left: calc(-0.5 * var(--half-gutter));
  width: var(--half-gutter);
  height: var(--half-gutter);
  background: white;
  border: 1px solid;
  border-radius: 50%;
}

.timeline__item-title {
  font: var(--tagline);
  text-transform: uppercase;
}

.timeline__item-body {
  margin: 0 0 var(--lineheight-meta);
  font: var(--meta);
}

.timeline__item:last-child .timeline__item-body {
  margin-bottom: 0;
}

.timeline--autonumber .timeline__item-body:before {
  content: counter(timeline) ".";
  counter-increment: timeline;
}

.timeline__uncrop {
  display: none;
}

.timeline__items.is-truncated + .timeline__uncrop {
  display: block;
  margin: var(--half-gutter) 0;
}
