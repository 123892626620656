.breadcrumbs__list {
  padding-left: 0;
  font: var(--meta);
}

.breadcrumbs__item {
  display: inline-block;
}

.breadcrumbs__item:nth-child(n+2):before {
  content: " / ";
}

.breadcrumbs__link {
  border-bottom-style: none;
}

.breadcrumbs__link:hover,
.breadcrumbs__link:focus {
  border-bottom-style: solid;
}

.breadcrumbs__link:link {
  color: var(--alternate-link-color);
  border-bottom-color: var(--alternate-link-underline);
}

.breadcrumbs--in-footer .breadcrumbs__link:link {
  color: var(--link-color);
  border-bottom-color: var(--link-underline-focus);
}

@media (--small) {
  .breadcrumbs__item:not(:last-child) {
    display: none;
  }

  .breadcrumbs__item:last-child:before {
    content: "← ";
  }
}
