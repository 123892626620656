.article-series-nav {
  font: var(--meta);
}

.article-series-nav__header {
  border-top: 2px solid white;
}

.theme-lille .article-series-nav__header {
  border-top-color: var(--color-silver-tree);
}

.article-series-nav__hgroup {
  margin-top: var(--lineheight-tagline);
}

.article-series-nav__heading {
  display: inline;
  font: var(--tagline);
  text-transform: uppercase;
}

.article-series-nav__series-title {
  display: inline-block;
  margin: 0;
}

.article-series-nav__series-link {
  margin: var(--lineheight-tagline) 0 0;
}

.article-series-nav__list {
  padding: 0;
  margin: 0;
}

.article-series-nav__list-item {
  display: block;
  margin-top: var(--lineheight-tagline);
}

.article-series-nav__prev,
.article-series-nav__next {
  display: block;
  height: 100%;
  border-bottom-style: none;
  background-color: white;
  background-repeat: no-repeat;
  padding: var(--lineheight-tagline) var(--half-gutter);
  color: var(--alternate-link-color);
}

.theme-lille .article-series-nav__prev,
.theme-lille .article-series-nav__next {
  border: 2px solid var(--color-silver-tree);
}

.article-series-nav__prev {
  padding-left: calc(2 * var(--half-gutter) + 35px);
  background-image: url(../images/previous.svg);
  background-position: var(--half-gutter);
}

.article-series-nav__next {
  padding-right: calc(2 * var(--half-gutter) + 35px);
  background-image: url(../images/next.svg);
  background-position: right var(--half-gutter) center;
}

.article-series-nav__link-title {
  display: block;
  font: var(--small-body);
  font-weight: 600;
}

@media (--medium-plus-up) {
  .article-series-nav__header {
    display: flex;
    gap: var(--half-gutter);
  }

  .article-series-nav__hgroup,
  .article-series-nav__series-link,
  .article-series-nav__list-item {
    flex: 1;
  }

  .article-series-nav__series-link {
    text-align: right;
  }

  .article-series-nav__list {
    display: flex;
    gap: var(--half-gutter);
  }
}
