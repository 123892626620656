.author-list {
  display: inline;
  margin: 0;
  padding: 0;
}

.author-list__author {
  display: inline;
}

.author-list__author:nth-last-child(n+2):after {
  content: "; "
}

.author-list__author:nth-last-child(2):after {
  content: attr(data-conjunction);
}
