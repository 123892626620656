.picture {
  margin: 0;
  padding: var(--half-gutter);
  background: white;
  font: var(--meta);
  overflow: auto; /* In case of long words; SNL don't want to hyphenate/wrap */
}

.theme-lille .picture:not(.picture--in-gallery) {
  border-block: 2px solid var(--color-silver-tree);
}

.picture__caption p {
  display: inline;
}

.picture__image {
  width: auto;
  height: auto;
  max-width: 100%;
  vertical-align: top;
}

.picture__caption {
  margin-top: 0.5rem;
}

.picture__attribution {
  font: var(--meta-small);
  margin-top: var(--half-gutter);
}

/* Small variant */
.picture--small {
  display: flex;
  align-items: flex-start;
}

.picture--small .picture__image-wrapper {
  width: 50%;
}

.picture--small .picture__caption {
  width: 50%;
  padding-left: var(--half-gutter);
}

/* Zoomable variant */
.picture--zoomable .picture__image-wrapper {
  position: relative;
}

/* Gallery variant */
.picture--in-gallery.picture--zoomable .picture__image {
  transition: height 0.2s ease-in-out;
}

.picture__zoom-trigger {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
}

.picture__zoom-icon {
  width: calc(16px + var(--gutter));
  height: calc(16px + var(--gutter));
  position: absolute;
  bottom: 0;
  right: 0;
  padding: var(--half-gutter);
  background: radial-gradient(closest-side, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0));
}

.picture__zoom-trigger.is-zoomed .picture__zoom-icon {
  display: none;
}

/* Media queries */
@media (--small) {
  .picture--medium-portrait {
    display: flex;
    align-items: flex-start;
  }

  .picture--medium-portrait .picture__image-wrapper {
    max-width: 50%;
  }

  .picture--medium-portrait .picture__caption {
    max-width: 50%;
    padding-left: var(--half-gutter);
  }

  .picture__zoom-trigger {
    display: none;
  }
}

@media (--medium-up) {
  .theme-lille .picture:not(.picture--in-gallery) {
    border: 2px solid var(--color-silver-tree);
  }

  .picture--in-gallery {
    padding: 0;
    display: table; /* Hack to make caption same width as image */
    width: 100px;
  }

  .picture--in-gallery .picture__image {
    height: 160px;
    max-width: none;
  }

  .picture--in-gallery.picture--zoomed .picture__image {
    height: 320px;
  }
}

@media (--medium-plus-up) {
  .picture--in-gallery .picture__image {
    height: 200px;
  }

  .picture--in-gallery.picture--zoomed .picture__image {
    height: 400px;
  }
}

@media (--large-up) {
  .picture--in-gallery .picture__image {
    height: 230px;
  }

  .picture--in-gallery.picture--zoomed .picture__image {
    height: 460px;
  }
}

@media (--xlarge-up) {
  .picture--in-gallery .picture__image {
    height: 250px;
  }

  .picture--in-gallery.picture--zoomed .picture__image {
    height: 500px;
  }
}

@media (--xlarge-plus-up) {
  .picture--in-gallery .picture__image {
    height: 300px;
  }

  .picture--in-gallery.picture--zoomed .picture__image {
    height: 600px;
  }
}
