.notice {
  background: var(--pale-canary);
  border: 1px solid;
  position: relative;
}

.notice:after {
  content: "";
  display: block;
  clear: right;
}

.notice--fullwidth {
  border-style: none none solid;
}

.notice__content {
  padding: var(--half-gutter);
}

.notice--fullwidth .notice__content {
  padding-left: 0;
  padding-right: 0;
}

.notice--closeable .notice__content {
  padding-right: var(--gutter);
}

.notice__content:after {
  content: "";
  display: block;
  clear: right;
}

.notice__figure {
  margin: 0 0 0 var(--half-gutter);
  float: right;
}

.notice__image {
  max-width: 200px;
  vertical-align: top;
}

.notice__close {
  cursor: pointer;
  border: none;
  background: transparent;
  padding: var(--half-gutter);
  position: absolute;
  top: 0;
  right: 0;
}

@media (--medium-up) {
  .notice--fullwidth .notice__content {
    padding: var(--half-gutter);
  }
}

@media (--large-up) {
  .notice__content {
    padding: var(--gutter);
  }
}
